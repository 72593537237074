/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

const StructuredExtractionSchemaExclusions = ({
    schemaExclusions,
    setSchemaExclusions,
}: {
    schemaExclusions: any;
    setSchemaExclusions: Dispatch<SetStateAction<any>>;
}) => {
    const updateExclusion = (key: string, active: boolean) => {
        setSchemaExclusions({
            ...schemaExclusions,
            [key]: active,
        });
    };

    /* eslint-disable-next-line no-console */
    console.log(schemaExclusions?.excludeHawb);

    return (
        <>
            <Typography>Schema Exclusions/Inclusions</Typography>
            <Grid container>
                <Grid item xs={5}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">Exclude HAWB</Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={schemaExclusions?.excludeHawb}
                            onChange={(e) => {
                                updateExclusion(
                                    "excludeHawb",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">
                            Exclude Special Instructions
                        </Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={
                                schemaExclusions?.excludeSpecialInstructions
                            }
                            onChange={(e) => {
                                updateExclusion(
                                    "excludeSpecialInstructions",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">Exclude MAWB</Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={schemaExclusions?.excludeMawb}
                            onChange={(e) => {
                                updateExclusion(
                                    "excludeMawb",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">
                            Include total weight
                        </Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={schemaExclusions?.includeTotalWeight}
                            onChange={(e) => {
                                updateExclusion(
                                    "includeTotalWeight",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">
                            Strip spaces from HAWB
                        </Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={schemaExclusions?.stripSpacesFromHawb}
                            onChange={(e) => {
                                updateExclusion(
                                    "stripSpacesFromHawb",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7}>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption">
                            Strip airport code from HAWB
                        </Typography>
                        <Checkbox
                            sx={{ p: 0.5 }}
                            size="small"
                            checked={schemaExclusions?.stripAirportCodeFromHawb}
                            onChange={(e) => {
                                updateExclusion(
                                    "stripAirportCodeFromHawb",
                                    e.currentTarget.checked,
                                );
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};

export default StructuredExtractionSchemaExclusions;
