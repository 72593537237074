import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type UserImporterFormValues = {
    usersUrl: string;
};

const resolver: Resolver<UserImporterFormValues> = async (formValues) => {
    const errors: {
        [property in keyof UserImporterFormValues]?: {
            type: string;
            message: string;
        };
    } = {};

    const { usersUrl } = formValues;

    if (isNil(usersUrl) || isEmpty(usersUrl)) {
        errors.usersUrl = {
            type: "required",
            message: "Users URL is required",
        };
    }

    return {
        values: formValues,
        errors,
    };
};

const useImportUsersForm = () => {
    return useForm({ resolver });
};

export default useImportUsersForm;
