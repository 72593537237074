/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Alert,
    Autocomplete,
    Button,
    Checkbox,
    Grid,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import Select from "react-select";
import { OrderIdentifier } from "shared/generated/graphql";
import { isNilOrEmptyString } from "shared/string";
import {
    FuelBillingMethod,
    MeCompanyConfigurationFragment,
    OrderNamingScheme,
    SameDayDispatchCellHeader,
    StandardStopType,
    UpdateCompanyConfigurationInput,
    useCompaniesQuery,
    useCompanyConfigurationByCompanyUuidLazyQuery,
    useUpdateCompanyConfigurationMutation,
} from "../../generated/graphql";
import {
    CompanyConfigurationDetails,
    companyConfigurationDetails,
} from "./company-configurations-utils";

const CompanyConfigurations = () => {
    const [updateCompanyConfiguration, { loading: updateLoading }] =
        useUpdateCompanyConfigurationMutation();
    const [getConfiguration, { loading }] =
        useCompanyConfigurationByCompanyUuidLazyQuery();
    const [configuration, setConfiguration] = useState<
        MeCompanyConfigurationFragment | undefined
    >();
    const [newConfiguration, setNewConfiguration] =
        useState<UpdateCompanyConfigurationInput>({ uuid: "" });
    const { data: companiesData } = useCompaniesQuery();
    const [companyUuid, setCompanyUuid] = useState<string | null>(null);
    const companySelectOptions = useMemo(() => {
        return companiesData?.companies?.map((company) => {
            return {
                value: company.uuid,
                label: company.name,
            };
        });
    }, [companiesData]);

    const fetchConfiguration = async () => {
        if (!isNil(companyUuid)) {
            const res = await getConfiguration({
                variables: {
                    uuid: companyUuid,
                },
            });
            setConfiguration(
                res.data?.companyConfigurationByCompanyUuid ?? undefined,
            );
        }
    };

    useEffect(() => {
        fetchConfiguration();
    }, [companyUuid]);

    useEffect(() => {
        if (!isNil(configuration)) {
            const configCopy = { ...configuration };
            delete configCopy.__typename;
            setNewConfiguration(configCopy);
        } else {
            setNewConfiguration({ uuid: "" });
        }
    }, [configuration]);

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const DEFAULT_MESSAGE = "Error updating company configuration";
    const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);
    const [results, setResults] = useState({});

    const onSubmit = async () => {
        try {
            if (isNilOrEmptyString(newConfiguration?.uuid)) {
                throw new Error("Company configuration not found");
            }
            const response = await updateCompanyConfiguration({
                variables: {
                    updateCompanyConfigurationInput: {
                        ...newConfiguration,
                    },
                },
            });
            fetchConfiguration();
            const { errors } = response;
            if (!isEmpty(errors)) {
                setErrorMessage(
                    errors?.map((err) => err.message).join(", ") ??
                        DEFAULT_MESSAGE,
                );
                setErrorVisible(true);
            } else {
                setResults(response.data?.updateCompanyConfiguration ?? {});
                setSuccessVisible(true);
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
            /* eslint-disable no-console */
            console.error(e);
        }
    };

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully updated company configuration
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Company Configuration Editor
                </Typography>
                <Select
                    options={companySelectOptions}
                    onChange={(option) => {
                        if (!isNil(option) && !isNil(option.value)) {
                            setCompanyUuid(option.value);
                        }
                    }}
                    placeholder="Select company"
                />
                <Typography variant="subtitle1">
                    {`Note: For configs that are already exposed in Pallet's UI,
                    please edit from the UI directly if possible.`}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Name
                                </TableCell>
                                <TableCell
                                    sx={{ fontWeight: "bold", width: 350 }}
                                >
                                    Value
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Type (? = optional)
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Description
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>
                                    Exposed
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newConfiguration.uuid.length > 0 && !loading ? (
                                Object.entries(newConfiguration).map(
                                    ([name, value]) => {
                                        if (name === "uuid") {
                                            return null;
                                        }
                                        const details:
                                            | CompanyConfigurationDetails
                                            | null
                                            | undefined =
                                            companyConfigurationDetails.find(
                                                (detail) =>
                                                    detail.name === name,
                                            );
                                        let valueField;
                                        const configType = details?.type ?? "";
                                        if (
                                            ["Boolean", "Boolean?"].includes(
                                                configType,
                                            )
                                        ) {
                                            valueField = (
                                                <Switch
                                                    checked={value ?? false}
                                                    onChange={(e) => {
                                                        setNewConfiguration({
                                                            ...newConfiguration,
                                                            [name]: e.target
                                                                .checked,
                                                        });
                                                    }}
                                                />
                                            );
                                        } else if (
                                            ["Int", "Int?"].includes(configType)
                                        ) {
                                            valueField = (
                                                <TextField
                                                    value={value}
                                                    onChange={(e) => {
                                                        const parsedInt =
                                                            parseInt(
                                                                e.target.value,
                                                                10,
                                                            );
                                                        setNewConfiguration({
                                                            ...newConfiguration,
                                                            [name]: !Number.isNaN(
                                                                parsedInt,
                                                            )
                                                                ? parsedInt
                                                                : null,
                                                        });
                                                    }}
                                                    size="small"
                                                />
                                            );
                                        } else if (
                                            ["Decimal", "Decimal?"].includes(
                                                configType,
                                            )
                                        ) {
                                            valueField = (
                                                <TextField
                                                    value={value}
                                                    onChange={(e) => {
                                                        const parsedFloat =
                                                            parseFloat(
                                                                e.target.value,
                                                            );
                                                        setNewConfiguration({
                                                            ...newConfiguration,
                                                            [name]: !Number.isNaN(
                                                                parsedFloat,
                                                            )
                                                                ? parsedFloat
                                                                : null,
                                                        });
                                                    }}
                                                    size="small"
                                                />
                                            );
                                        } else if (
                                            ["DateTime", "DateTime?"].includes(
                                                configType,
                                            )
                                        ) {
                                            valueField = (
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <TimePicker
                                                        value={dayjs(value)}
                                                        onChange={(
                                                            newDate: Dayjs | null,
                                                        ) => {
                                                            if (
                                                                !isNil(
                                                                    newDate,
                                                                ) &&
                                                                newDate.isValid()
                                                            ) {
                                                                setNewConfiguration(
                                                                    {
                                                                        ...newConfiguration,
                                                                        [name]: newDate.set(
                                                                            "seconds",
                                                                            0,
                                                                        ),
                                                                    },
                                                                );
                                                            } else
                                                                setNewConfiguration(
                                                                    {
                                                                        ...newConfiguration,
                                                                        [name]: null,
                                                                    },
                                                                );
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            );
                                        } else if (
                                            [
                                                "StandardStopType",
                                                "StandardStopType?",
                                                "FuelBillingMethod",
                                                "FuelBillingMethod?",
                                                "SameDayDispatchCellHeader",
                                                "SameDayDispatchCellHeader?",
                                                "OrderNamingScheme",
                                                "OrderNamingScheme?",
                                                "OrderIdentifier",
                                                "OrderIdentifier?",
                                            ].includes(configType)
                                        ) {
                                            let options: readonly any[] = [];
                                            switch (configType) {
                                                case "StandardStopType":
                                                case "StandardStopType?":
                                                    options =
                                                        Object.values(
                                                            StandardStopType,
                                                        );
                                                    break;
                                                case "FuelBillingMethod":
                                                case "FuelBillingMethod?":
                                                    options =
                                                        Object.values(
                                                            FuelBillingMethod,
                                                        );
                                                    break;
                                                case "SameDayDispatchCellHeader":
                                                case "SameDayDispatchCellHeader?":
                                                    options = Object.values(
                                                        SameDayDispatchCellHeader,
                                                    );
                                                    break;
                                                case "OrderNamingScheme":
                                                case "OrderNamingScheme?":
                                                    options =
                                                        Object.values(
                                                            OrderNamingScheme,
                                                        );
                                                    break;
                                                case "OrderIdentifier":
                                                case "OrderIdentifier?":
                                                    options =
                                                        Object.values(
                                                            OrderIdentifier,
                                                        );
                                                    break;
                                                default:
                                                    break;
                                            }
                                            valueField = (
                                                <Autocomplete
                                                    size="small"
                                                    sx={{
                                                        backgroundColor:
                                                            "white",
                                                        width: "100%",
                                                    }}
                                                    value={value}
                                                    options={options}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...params}
                                                            onKeyDown={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                            size="small"
                                                        />
                                                    )}
                                                    onChange={(_, option) => {
                                                        setNewConfiguration({
                                                            ...newConfiguration,
                                                            [name]: option,
                                                        });
                                                    }}
                                                    disableClearable={
                                                        !configType.endsWith(
                                                            "?",
                                                        )
                                                    }
                                                />
                                            );
                                        } else {
                                            valueField = (
                                                <TextField
                                                    value={value}
                                                    onChange={(e) => {
                                                        setNewConfiguration({
                                                            ...newConfiguration,
                                                            [name]: e.target
                                                                .value,
                                                        });
                                                    }}
                                                    size="small"
                                                />
                                            );
                                        }

                                        const isChanged = !isNil(
                                            Object.entries(
                                                configuration ?? {},
                                            ).find(
                                                ([oldName, oldVal]) =>
                                                    oldName === name &&
                                                    oldVal !== value,
                                            ),
                                        );
                                        return (
                                            <TableRow
                                                key={name}
                                                sx={{
                                                    backgroundColor: isChanged
                                                        ? "#FDFD96"
                                                        : undefined,
                                                }}
                                            >
                                                <TableCell>{name}</TableCell>
                                                <TableCell>
                                                    {!isNil(details)
                                                        ? valueField
                                                        : `${String(value)} -- UPDATE TOOL TO EDIT`}
                                                </TableCell>
                                                <TableCell>
                                                    {configType}
                                                </TableCell>
                                                <TableCell>
                                                    {details?.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            details?.exposedToUser ===
                                                            true
                                                        }
                                                        disabled
                                                        sx={{ m: 0, p: 0 }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                )
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        No configuration found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={onSubmit}
                    disabled={updateLoading}
                    variant="contained"
                >
                    Update Company Configuration
                </Button>
            </Grid>
            <Grid item xs={12}>
                <ReactJson src={results} />
            </Grid>
        </Grid>
    );
};

export default CompanyConfigurations;
