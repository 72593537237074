import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, Fade, IconButton, TableCell, TableRow } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
    SchemaCompanyMappingsDocument,
    SchemaCompanyMappingsQuery,
    SearchSchemasDocument,
    useContactQuery,
    useDeleteSchemaCompanyMappingMutation,
    useUpdateSchemaCompanyMappingMutation,
} from "../../../../generated/graphql";
import CompanySelect from "../../company-select";
import TerminalSelect from "../../terminal-select";
import useSchemaStore from "../schema-store";

const SchemaCompanyMappingRow = ({
    schemaCompanyMapping,
    selectedSchemaCompanyMappingId,
    setSelectedSchemaCompanyMappingId,
}: {
    schemaCompanyMapping: SchemaCompanyMappingsQuery["schemaCompanyMappings"][0];
    selectedSchemaCompanyMappingId: string | undefined;
    setSelectedSchemaCompanyMappingId: Dispatch<
        SetStateAction<string | undefined>
    >;
}) => {
    const deselectAllSchemaDocuments = useSchemaStore(
        (state) => state.deselectAllSchemaDocuments,
    );
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [updateSchemaCompanyMapping] = useUpdateSchemaCompanyMappingMutation({
        refetchQueries: [SchemaCompanyMappingsDocument, SearchSchemasDocument],
    });
    const [deleteSchemaCompanyMapping] = useDeleteSchemaCompanyMappingMutation({
        refetchQueries: [SchemaCompanyMappingsDocument, SearchSchemasDocument],
    });
    const { data: contactData } = useContactQuery({
        variables: {
            uuid: schemaCompanyMapping.contactUuid,
        },
    });

    const onDelete = async () => {
        await deleteSchemaCompanyMapping({
            variables: {
                id: schemaCompanyMapping.id,
            },
        });
    };

    const onChangeTerminal = async (uuid: string | null) => {
        await updateSchemaCompanyMapping({
            variables: {
                updateSchemaCompanyMappingInput: {
                    id: schemaCompanyMapping.id,
                    terminalUuid: uuid,
                },
            },
        });
    };

    const onCheck = async () => {
        await updateSchemaCompanyMapping({
            variables: {
                updateSchemaCompanyMappingInput: {
                    id: schemaCompanyMapping.id,
                    isActive: !schemaCompanyMapping.isActive,
                },
            },
        });
    };

    const onClick = () => {
        if (schemaCompanyMapping.id !== selectedSchemaCompanyMappingId) {
            deselectAllSchemaDocuments();
            setSelectedSchemaCompanyMappingId(schemaCompanyMapping.id);
        }
    };

    return (
        <TableRow
            sx={{
                backgroundColor:
                    selectedSchemaCompanyMappingId ===
                        schemaCompanyMapping.id || isHovered
                        ? "#f0f0f0"
                        : undefined,
                cursor: "pointer",
            }}
            onMouseEnter={() => {
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                setIsHovered(false);
            }}
            onClick={() => {
                onClick();
            }}
        >
            <TableCell>
                <CompanySelect
                    currentCompanyUuid={schemaCompanyMapping.companyUuid}
                    onChange={() => {}}
                    isText
                />
            </TableCell>
            <TableCell>{contactData?.contact.displayName}</TableCell>
            <TableCell sx={{ width: 175 }}>
                <TerminalSelect
                    companyUuid={schemaCompanyMapping.companyUuid}
                    currentTerminalUuid={schemaCompanyMapping.terminalUuid}
                    setTerminalUuid={onChangeTerminal}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={schemaCompanyMapping.isActive}
                    onChange={(e) => {
                        e.stopPropagation();
                        onCheck();
                    }}
                />
            </TableCell>
            <TableCell>
                <Fade in={isHovered}>
                    <IconButton sx={{ float: "right" }} onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Fade>
            </TableCell>
        </TableRow>
    );
};
export default React.memo(SchemaCompanyMappingRow);
