import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { Dispatch, SetStateAction, memo } from "react";
import { SearchSchemasQuery } from "../../../generated/graphql";
import SchemaListRow from "./schema-list-row";

export type Schema = SearchSchemasQuery["searchSchemas"][0];
const SchemaTable = ({
    companyUuid,
    schemas,
    selectedSchemas,
    setSelectedSchemas,
    setSchemaUuidToGoTo,
}: {
    companyUuid: string | undefined;
    schemas: Schema[];
    selectedSchemas: Schema[];
    setSelectedSchemas: Dispatch<SetStateAction<Schema[]>>;
    setSchemaUuidToGoTo: Dispatch<SetStateAction<string | undefined>>;
}) => {
    return (
        <Table size="small" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            checked={schemas?.every((s) =>
                                selectedSchemas.some(
                                    (ss) => ss.uuid === s.uuid,
                                ),
                            )}
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    setSelectedSchemas(schemas ?? []);
                                } else {
                                    setSelectedSchemas([]);
                                }
                            }}
                        />
                    </TableCell>
                    <TableCell width={125}>Created At</TableCell>
                    <TableCell width={125}>Updated At</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Scanned Document Type</TableCell>
                    <TableCell>Is Active</TableCell>
                    <TableCell># Companies Mapped</TableCell>
                    <TableCell># Test Documents</TableCell>
                    <TableCell>Mappings</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {schemas?.map((schema) => (
                    <SchemaListRow
                        key={schema.uuid}
                        isSelected={selectedSchemas.some(
                            (s) => s.uuid === schema.uuid,
                        )}
                        schema={schema}
                        onSelect={() => {
                            setSelectedSchemas((prevState) => {
                                if (
                                    prevState.some(
                                        (s) => s.uuid === schema.uuid,
                                    )
                                ) {
                                    return prevState.filter(
                                        (s) => s.uuid !== schema.uuid,
                                    );
                                }
                                return [...prevState, schema];
                            });
                        }}
                        onGoToSchema={() => {
                            setSchemaUuidToGoTo(schema.uuid);
                        }}
                        companyUuid={companyUuid}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export default memo(SchemaTable);
