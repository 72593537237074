import { isNil } from "lodash";
import { useState } from "react";
import {
    SchemaDocumentsBySchemaCompanyMappingDocument,
    SearchSchemasDocument,
    useCreateSchemaDocumentMutation,
    useGenerateScannedOrderPreSignedPutUrlsLazyQuery,
} from "../../../../generated/graphql";

const useSchemaDocumentUpload = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [generatePreSignedPutUrls] =
        useGenerateScannedOrderPreSignedPutUrlsLazyQuery();
    const [createSchemaDocument] = useCreateSchemaDocumentMutation({
        refetchQueries: [
            SearchSchemasDocument,
            SchemaDocumentsBySchemaCompanyMappingDocument,
        ],
    });

    const uploadFiles = async ({
        schemaUuid,
        schemaCompanyMappingUuid,
        files,
        onFinish,
    }: {
        schemaUuid: string;
        schemaCompanyMappingUuid: string;
        files: File[];
        onFinish: () => void;
    }) => {
        setLoading(true);
        try {
            const preSignedPutUrls = await generatePreSignedPutUrls({
                variables: {
                    preSignedUrlRequests: files.map((file) => {
                        return {
                            filename: file.name,
                            filetype: file.type,
                        };
                    }),
                },
            });
            await Promise.all(
                files.map(async (file, idx) => {
                    const options = { headers: { "Content-Type": file.type } };
                    const preSignedUrl =
                        preSignedPutUrls.data
                            ?.generateScannedOrderPreSignedPutUrls[idx];
                    if (isNil(preSignedUrl)) {
                        // eslint-disable-next-line no-console
                        console.error(
                            `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
                        );
                        return null;
                    }
                    return fetch(preSignedUrl.url, {
                        method: "PUT",
                        body: file,
                        ...options,
                    });
                }),
            );

            await Promise.all(
                files.map((file, idx) => {
                    const preSignedUrl =
                        preSignedPutUrls.data
                            ?.generateScannedOrderPreSignedPutUrls[idx];
                    if (isNil(preSignedUrl)) {
                        /* eslint-disable-next-line no-console */
                        console.error(
                            `Could not find pre-signed URL for file - name: ${file.name}, type: ${file.type}, size: ${file.size}`,
                        );
                        return null;
                    }

                    return createSchemaDocument({
                        variables: {
                            createSchemaDocumentInput: {
                                schemaUuid,
                                schemaCompanyMappingUuid,
                                schemaDocument: {
                                    bucket: preSignedUrl.bucket,
                                    key: preSignedUrl.key,
                                },
                            },
                        },
                    });
                }),
            );

            onFinish();
        } finally {
            setLoading(false);
        }
    };

    return { loading, uploadFiles };
};

export default useSchemaDocumentUpload;
