import CodeGen from "../components/code-gen";
import { RequireAuth } from "../hooks/use-auth";

const CodeGenPage = () => {
    return (
        <RequireAuth>
            <CodeGen />
        </RequireAuth>
    );
};

export default CodeGenPage;
