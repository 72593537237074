/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Stack,
} from "@mui/material";
import { isNil } from "lodash";
import React, { Dispatch, SetStateAction, useState } from "react";
import Select from "react-select";
import { StructuredExtractionOverride } from "../types";

const AddOverrideDialog = ({
    open,
    setOpen,
    setSchemaOverride,
    title,
    basePath,
    overrides,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSchemaOverride: Dispatch<SetStateAction<any>>;
    title: string;
    basePath: string[];
    overrides: StructuredExtractionOverride[];
}) => {
    const options = overrides.map((o) => ({ ...o, value: o.label }));
    const [override, setOverride] = useState<StructuredExtractionOverride>();
    const onAdd = () => {
        if (isNil(override)) return;
        const path = basePath.concat(override.path);
        const newKey = path[path.length - 1];

        if (isNil(newKey)) return;

        setSchemaOverride((prev: any) => {
            let current = prev;
            path.slice(0, -1).forEach((key) => {
                if (!(key in current)) {
                    current[key] = {};
                }
                current = current[key];
            });
            current[newKey] = {
                ...current[newKey],
                description: override.default,
            };
            return prev;
        });
        setOverride(undefined);
        setOpen(false);
    };

    return (
        <Dialog
            sx={{ zIndex: 2000 }}
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <Stack sx={{ height: 250, px: 3 }}>
                <Select
                    options={options}
                    onChange={(option: StructuredExtractionOverride | null) => {
                        if (!isNil(option)) {
                            setOverride(option);
                        }
                    }}
                    placeholder="Select override"
                    value={override ?? null}
                />
            </Stack>
            <DialogActions>
                <Button onClick={onAdd}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddOverrideDialog;
