import {
    Grid,
    FormHelperText,
    InputLabel,
    TextField,
    Button,
    Snackbar,
    Alert,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableRow,
} from "@mui/material";
import { sentenceCase } from "change-case";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    TestOrdersDocument,
    useTestOrderQuery,
    useUpdateTestOrderMutation,
} from "../../generated/graphql";
import AddTestOrderCustomChargeModal from "./add-test-order-custom-charge-modal";
import useEditTestOrderForm, {
    EditTestOrderFormValues,
} from "./use-edit-test-order-form";

interface EditTestOrderFormProps {
    testOrderUuid: string;
}

const EditTestOrderForm = ({ testOrderUuid }: EditTestOrderFormProps) => {
    const navigate = useNavigate();
    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useEditTestOrderForm();

    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [createErrorVisible, setCreateErrorVisible] = useState(false);

    const [addCustomChargeModalOpen, setAddCustomChargeModalOpen] =
        useState(false);

    const { data: testOrderData } = useTestOrderQuery({
        variables: { findTestOrderInput: { uuid: testOrderUuid } },
    });

    const testOrder = useMemo(() => {
        return testOrderData?.testOrder?.testOrder;
    }, [testOrderData]);

    useEffect(() => {
        const order = testOrderData?.testOrder?.testOrder;
        if (isNil(order)) {
            return;
        }
        reset({
            orderUuid: order.order.uuid,
            totalCharge: order.totalCharge.toString(),
            totalFreightCharge: order.totalFreightCharge.toString(),
            totalFuelCharge: order.totalFuelCharge.toString(),
        });
    }, [testOrderData]);

    const [updateTestOrder, { loading: updateTestOrderLoading }] =
        useUpdateTestOrderMutation({ refetchQueries: [TestOrdersDocument] });

    const onSubmit: SubmitHandler<EditTestOrderFormValues> = async (data) => {
        const { orderUuid, totalCharge, totalFreightCharge, totalFuelCharge } =
            data;
        try {
            const response = await updateTestOrder({
                variables: {
                    updateTestOrderInput: {
                        uuid: testOrderUuid,
                        orderUuid,
                        totalCharge: parseFloat(totalCharge),
                        totalFreightCharge: parseFloat(totalFreightCharge),
                        totalFuelCharge: parseFloat(totalFuelCharge),
                    },
                },
            });
            if (response.data?.updateTestOrder.success === true) {
                reset();
                setCreateSuccessVisible(true);
                navigate("/test-orders");
            } else {
                setCreateErrorVisible(true);
            }
        } catch (e) {
            setCreateErrorVisible(true);
        }
    };

    return (
        <Grid container spacing={2} style={{ padding: 12 }}>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setCreateSuccessVisible(false)}
                open={createSuccessVisible}
            >
                <Alert>Created</Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setCreateErrorVisible(false)}
                open={createErrorVisible}
            >
                <Alert severity="error">Failed to save</Alert>
            </Snackbar>
            <Grid item xs={4}>
                <Controller
                    name="orderUuid"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Order UUID</InputLabel>
                            <TextField
                                name="orderUuid"
                                size="small"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.orderUuid)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.orderUuid) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.orderUuid?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Expected Total Charge</InputLabel>
                            <TextField
                                name="totalCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalFreightCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>
                                Expected Total Freight Charge
                            </InputLabel>
                            <TextField
                                name="totalFreightCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalFreightCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalFreightCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalFreightCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalFuelCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Expected Total Fuel Charge</InputLabel>
                            <TextField
                                name="totalFuelCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalFuelCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalFuelCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalFuelCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Custom Charges</Typography>
                <Table>
                    <TableHead>
                        <TableCell>UUID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Billing Method</TableCell>
                        <TableCell>Expected Total Charge</TableCell>
                    </TableHead>
                    {testOrder?.testOrderCustomCharges?.map(
                        (testOrderCustomCharge) => {
                            return (
                                <TableRow key={testOrderCustomCharge.uuid}>
                                    <TableCell>
                                        {
                                            testOrderCustomCharge.customCharge
                                                .uuid
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {!isEmpty(
                                            testOrderCustomCharge.customCharge
                                                .name,
                                        )
                                            ? testOrderCustomCharge.customCharge
                                                  .name
                                            : testOrderCustomCharge.customCharge
                                                  ?.accessorialTemplate?.name}
                                    </TableCell>
                                    <TableCell>
                                        {sentenceCase(
                                            testOrderCustomCharge.customCharge
                                                .billingMethod,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {testOrderCustomCharge.total}
                                    </TableCell>
                                </TableRow>
                            );
                        },
                    )}
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={updateTestOrderLoading}
                    onClick={() => setAddCustomChargeModalOpen(true)}
                >
                    Add Custom Charge
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    disabled={updateTestOrderLoading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Save
                </Button>
            </Grid>
            {!isNil(testOrder) && (
                <AddTestOrderCustomChargeModal
                    testOrder={testOrder}
                    open={addCustomChargeModalOpen}
                    onClose={() => setAddCustomChargeModalOpen(false)}
                />
            )}
        </Grid>
    );
};

export default EditTestOrderForm;
