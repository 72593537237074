import { Box } from "@mui/material";
import React from "react";
import { TabPanelProps } from "./index";

function TabPanel<T>(props: TabPanelProps<T>) {
    const { children, panelValue, selectedValue, ...other } = props;
    return (
        <Box
            hidden={selectedValue !== panelValue}
            id={`tab-panel-${panelValue}`}
            aria-labelledby={`tab-panel-${panelValue}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            {panelValue === selectedValue && <Box>{children}</Box>}
        </Box>
    );
}

export default TabPanel;
