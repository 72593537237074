import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
    RateType,
    TariffGroupType,
    TariffType,
    TariffZoneType,
} from "../../generated/graphql";

yup.addMethod(yup.object, "dayjs", function method(message) {
    return this.test("dayjs", message, function validate(value) {
        if (isNil(value)) {
            return true;
        }
        return dayjs.isDayjs(value);
    });
});

export const importTariffFormSchema = yup.object({
    companyUuid: yup.string().required(),
    contactUuid: yup.string().required(),
    serviceUuids: yup.array().of(yup.string().required()),
    autoSelectAllServices: yup.boolean().required(),
    name: yup.string().required(),
    tariffZoneType: yup
        .mixed<TariffZoneType>()
        .oneOf(Object.values(TariffZoneType))
        .required(),
    tariffZoneGroupId: yup.string().nullable(),
    unitType: yup
        .mixed<TariffType>()
        .oneOf(Object.values(TariffType))
        .required(),
    rateType: yup.mixed<RateType>().oneOf(Object.values(RateType)).required(),
    tariffGroupType: yup
        .mixed<TariffGroupType>()
        .oneOf(Object.values(TariffGroupType))
        .required(),
    useActualWeight: yup.boolean().required(),
    // @ts-expect-error dayjs extension not recognized
    startDate: yup.object().nullable().dayjs(),
    // @ts-expect-error dayjs extension not recognized
    endDate: yup.object().nullable().dayjs(),
    fuelProfileUuid: yup.string().required(),
    tariffUrl: yup.string().required(),
});

export type ImportTariffFormValuesV2 = yup.InferType<
    typeof importTariffFormSchema
>;

export const useImportTariffFormV2 = () => {
    const form = useForm({
        resolver: yupResolver(importTariffFormSchema),
        mode: "all",
    });

    return { form };
};
