import Canary from "../components/canary";
import { RequireAuth } from "../hooks/use-auth";

const CanaryPage = () => {
    return (
        <RequireAuth>
            <Canary />
        </RequireAuth>
    );
};

export default CanaryPage;
