import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type CreateTestOrderFormValues = {
    orderUuid: string;

    totalCharge: string;

    totalFreightCharge: string;

    totalFuelCharge: string;
};

const resolver: Resolver<CreateTestOrderFormValues> = async (formValues) => {
    const errors: {
        [property in keyof CreateTestOrderFormValues]?: {
            type: string;
            message: string;
        };
    } = {};

    const { orderUuid, totalCharge, totalFreightCharge, totalFuelCharge } =
        formValues;

    if (isNil(orderUuid) || isEmpty(orderUuid)) {
        errors.orderUuid = {
            type: "required",
            message: "Order is required",
        };
    }

    if (isNil(totalCharge) || isEmpty(totalCharge)) {
        errors.totalCharge = {
            type: "required",
            message: "Total Charge is required",
        };
    }

    if (isNil(totalFreightCharge) || isEmpty(totalFreightCharge)) {
        errors.totalFreightCharge = {
            type: "required",
            message: "Total Freight Charge is required",
        };
    }

    if (isNil(totalFuelCharge) || isEmpty(totalFuelCharge)) {
        errors.totalFuelCharge = {
            type: "required",
            message: "Total Fuel Charge is required",
        };
    }

    return {
        values: formValues,
        errors,
    };
};

const useCreateTestOrderForm = () => {
    return useForm({ resolver });
};

export default useCreateTestOrderForm;
