import UsersImporter from "../../components/users-importer/users-importer";
import { RequireAuth } from "../../hooks/use-auth";

const UsersImporterPage = () => {
    return (
        <RequireAuth>
            <UsersImporter />
        </RequireAuth>
    );
};

export default UsersImporterPage;
