import { isEmpty, isNil } from 'lodash';

export const allEqual = <T>(array: T[]): boolean => {
  return array.every((v) => v === array[0]);
};

/**
 * Convert an array of nilable T to non-nilable T
 *
 * @param array
 */
// eslint-disable-next-line import/prefer-default-export
export const filterNotNil = <T>(array: Array<T | undefined | null>): T[] => {
  return array.filter((v): v is T => !isNil(v));
};

export const uniqueVals = <T>(array: T[]): T[] => {
  return array.filter((c, index) => {
    return array.indexOf(c) === index;
  });
};

export const uniqueNotNilVals = <T>(
  array: Array<T | undefined | null>,
): T[] => {
  return uniqueVals(filterNotNil(array));
};

export const filterNotNilOrEmpty = <T>(
  array: (T | undefined | null)[],
): T[] => {
  return array.flatMap((v) => (isNil(v) || isEmpty(v) ? [] : [v]));
};
