import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type CreateTestOrderCustomChargeFormValues = {
    customChargeUuid: string;

    total: string;
};

const resolver: Resolver<CreateTestOrderCustomChargeFormValues> = async (
    formValues,
) => {
    const errors: {
        [property in keyof CreateTestOrderCustomChargeFormValues]?: {
            type: string;
            message: string;
        };
    } = {};

    const { customChargeUuid, total } = formValues;

    if (isNil(customChargeUuid) || isEmpty(customChargeUuid)) {
        errors.customChargeUuid = {
            type: "required",
            message: "Custom Charge is required",
        };
    }

    if (isNil(total) || isEmpty(total)) {
        errors.total = {
            type: "required",
            message: "Total is required",
        };
    }

    return {
        values: formValues,
        errors,
    };
};

const useCreateTestOrderCustomChargeForm = () => {
    return useForm({ resolver });
};

export default useCreateTestOrderCustomChargeForm;
