import { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import {
    ExtractionType,
    FieldExtractorEntity,
    FieldMapping,
    useShallowContactsQuery,
} from "../../../generated/graphql";
import { enumKeys } from "../../../utils/enum";

type EditFieldExtractorModalProps = {
    companyUuid: string | null;
    isOpen: boolean;
    onClose: () => void;
    fieldExtractor: FieldExtractorEntity | undefined;
    handleSave: ({
        type,
        value,
        mapping,
    }: {
        type: ExtractionType;
        value: string;
        mapping: FieldMapping;
    }) => void;
};

const EditFieldExtractorModal = ({
    companyUuid,
    isOpen,
    onClose,
    handleSave,
    fieldExtractor,
}: EditFieldExtractorModalProps) => {
    const { data } = useShallowContactsQuery({
        variables: { companyUuid },
    });
    const [extractionType, setExtractionType] = useState<ExtractionType>();
    const [fieldMapping, setFieldMapping] = useState<FieldMapping>();
    const [extractorValue, setExtractorValue] = useState<string>("");

    useEffect(() => {
        if (isOpen && fieldExtractor) {
            setExtractionType(fieldExtractor.type);
            setFieldMapping(fieldExtractor.mapping);
            setExtractorValue(fieldExtractor.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const extractionTypeOptions = enumKeys(ExtractionType).map((key) => ({
        value: ExtractionType[key],
        label: ExtractionType[key],
    }));
    const fieldMappingOptions = enumKeys(FieldMapping).map((key) => ({
        value: FieldMapping[key],
        label: FieldMapping[key],
    }));
    const contactOptions = data?.contacts.map((contact) => ({
        value: contact.uuid,
        label: contact.displayName,
    }));

    return (
        <Modal
            contentLabel="Edit field extractor"
            style={{
                content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "600px",
                    width: "800px",
                },
            }}
            isOpen={isOpen}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "black",
                }}
            >
                <h3>Select extractor type</h3>
                <Select
                    required
                    onChange={(option) => {
                        if (option) {
                            setExtractionType(option.value as ExtractionType);
                        }
                    }}
                    options={extractionTypeOptions}
                    value={extractionTypeOptions.find(
                        (option) => option.value === extractionType,
                    )}
                    styles={{
                        control: (base) => ({
                            ...base,
                            width: "max-content",
                            minWidth: "100%",
                        }),
                        menu: (base) => ({
                            ...base,
                            width: "max-content",
                            minWidth: "100%",
                        }),
                    }}
                />
                {extractionType !== ExtractionType.Noop &&
                    extractionType !== ExtractionType.SelectAll &&
                    extractionType !== ExtractionType.Contact && (
                        <>
                            <h3>Set value</h3>
                            <input
                                required
                                onChange={(e) =>
                                    setExtractorValue(e.target.value)
                                }
                                value={extractorValue}
                            />
                        </>
                    )}
                {extractionType === ExtractionType.Contact && (
                    <>
                        <h3>Select contact</h3>
                        <Select
                            required
                            onChange={(option) => {
                                if (option) {
                                    setExtractorValue(option.value);
                                }
                            }}
                            value={contactOptions?.find(
                                (option) => option.value === extractorValue,
                            )}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                }),
                            }}
                            options={contactOptions}
                        />
                    </>
                )}
                <h3>Select field mapping</h3>
                <Select
                    required
                    onChange={(option) => {
                        if (option) {
                            setFieldMapping(option.value as FieldMapping);
                        }
                    }}
                    value={fieldMappingOptions.find(
                        (option) => option.value === fieldMapping,
                    )}
                    styles={{
                        control: (base) => ({
                            ...base,
                            width: "max-content",
                            minWidth: "100%",
                        }),
                        menu: (base) => ({
                            ...base,
                            width: "max-content",
                            minWidth: "100%",
                        }),
                    }}
                    options={fieldMappingOptions}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                <button
                    style={{ marginRight: "10px", height: "30px" }}
                    onClick={() => {
                        if (extractionType == null || fieldMapping == null) {
                            alert("All values are required");
                        } else {
                            handleSave({
                                type: extractionType,
                                value: extractorValue,
                                mapping: fieldMapping,
                            });
                        }
                    }}
                >
                    Save changes
                </button>
                <button onClick={onClose}>Discard</button>
            </div>
        </Modal>
    );
};

export default EditFieldExtractorModal;
