import { groupBy, isNil } from 'lodash';
import {
  AccessLevel,
  MeRoleFragment,
  MeRolePermissionFragment,
  PermissionResource,
} from './generated/graphql';

export type UserPermissionWithAccessLevel = {
  permissionResource: PermissionResource;
  accessLevel: AccessLevel | null;
};

export function getMaximumAccessLevel(
  permissions: MeRolePermissionFragment[],
): AccessLevel | null {
  const permissionAccessLevels = permissions.map((p) => p.accessLevel);
  if (permissionAccessLevels.includes(AccessLevel.Write)) {
    return AccessLevel.Write;
  }
  if (permissionAccessLevels.includes(AccessLevel.Read)) {
    return AccessLevel.Read;
  }
  return null;
}

export function buildUserPermissionsList(
  roles: MeRoleFragment[],
): UserPermissionWithAccessLevel[] {
  const permissions = roles.flatMap((role) => role.permissions);
  const permissionsByResource = groupBy(permissions, 'permissionResource');
  const finalUserPermissionsWithAccessLevel: UserPermissionWithAccessLevel[] =
    Object.keys(permissionsByResource).map((permissionResource) => ({
      permissionResource: permissionResource as PermissionResource,
      accessLevel: getMaximumAccessLevel(
        permissionsByResource?.[permissionResource] ?? [],
      ),
    }));
  return finalUserPermissionsWithAccessLevel;
}

export function getPermissionAccessLevel(
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  permissionResource: PermissionResource,
): AccessLevel | null {
  return (
    userPermissionWithAccessLevels.find(
      (userPermissionWithAccessLevel) =>
        userPermissionWithAccessLevel.permissionResource === permissionResource,
    )?.accessLevel ?? null
  );
}

export type PermissionFlags = {
  canRead: boolean;
  canWrite: boolean;
  hasMasterPermission: boolean;
};

export function getPermissionsFlags(
  userPermissionWithAccessLevels: UserPermissionWithAccessLevel[],
  permissionResource: PermissionResource,
): PermissionFlags {
  const accessLevel = getPermissionAccessLevel(
    userPermissionWithAccessLevels,
    permissionResource,
  );
  const masterPermissionResource = userPermissionWithAccessLevels.find(
    (userPermissionWithAccessLevel) =>
      userPermissionWithAccessLevel.permissionResource ===
      PermissionResource.Master,
  );
  if (!isNil(masterPermissionResource)) {
    return { canRead: true, canWrite: true, hasMasterPermission: true };
  }
  if (isNil(accessLevel)) {
    return { canRead: false, canWrite: false, hasMasterPermission: false };
  }
  if (accessLevel === AccessLevel.Write) {
    return { canRead: true, canWrite: true, hasMasterPermission: false };
  }

  return { canRead: true, canWrite: false, hasMasterPermission: false };
}
