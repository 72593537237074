import TariffImporterV2 from "../../components/tariff-importer-v2/tariff-importer-v2";
import { RequireAuth } from "../../hooks/use-auth";

const TariffImporterPageV2 = () => {
    return (
        <RequireAuth>
            <TariffImporterV2 />
        </RequireAuth>
    );
};

export default TariffImporterPageV2;
