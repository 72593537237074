import {
    Alert,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import {
    useImportCrownUsersMutation,
    useMeQuery,
} from "../../generated/graphql";
import useImportUsersForm, {
    UserImporterFormValues,
} from "./use-user-importer-form";

const UsersImporter = () => {
    const [importCrownUsers, { loading: importCrownUsersLoading }] =
        useImportCrownUsersMutation();
    const { data: meData } = useMeQuery({
        fetchPolicy: "cache-first",
    });
    const company = meData?.me?.company;

    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useImportUsersForm();

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const DEFAULT_MESSAGE = "Error importing users";
    const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);

    useEffect(() => {
        reset({
            usersUrl: "",
        });
    }, [reset]);

    const onSubmit: SubmitHandler<UserImporterFormValues> = async (data) => {
        const { usersUrl } = data;

        try {
            const response = await importCrownUsers({
                variables: {
                    importCrownUsersInput: {
                        usersUrl,
                    },
                },
            });
            const error = response.data?.importCrownUsers?.error;
            if (isNil(error)) {
                setErrorMessage(DEFAULT_MESSAGE);
                setSuccessVisible(true);
            } else {
                setErrorMessage(error);
                setErrorVisible(true);
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
            /* eslint-disable-next-line no-console */
            console.error(e);
        }
    };

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully imported users
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">Crown Users Importer</Typography>
                <Typography variant="h5">Company: {company?.name}</Typography>
                <Typography variant="subtitle1">
                    PLEASE MAKE SURE YOU LOGGED IN WITH THE COMPANY ACCOUNT THAT
                    YOU WANT TO CREATE THE USERS FOR
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="usersUrl"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Users URL</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                error={!isNil(errors.usersUrl)}
                                value={value}
                                onChange={onChange}
                            />
                            {!isNil(errors.usersUrl) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.usersUrl.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={importCrownUsersLoading}
                    variant="contained"
                >
                    Create Users
                </Button>
            </Grid>
        </Grid>
    );
};

export default UsersImporter;
