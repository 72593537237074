import { Check, ExpandMore } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import { useCompanyContactsQuery } from "../../generated/graphql";
import useStyles from "./general-styles";

export type Option = {
    value: string | undefined;
    label: string | undefined;
};

interface CustomerFilterButtonProps {
    companyUuid: string;
    selectedOption: Option | undefined;
    handleChange: (option: Option | undefined) => void;
}

const CustomerFilterButton = ({
    companyUuid,
    selectedOption,
    handleChange,
}: CustomerFilterButtonProps) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const styles = useStyles();
    const isAllSelected = selectedOption === undefined;
    const { data: contactsData } = useCompanyContactsQuery({
        variables: { companyUuid },
    });

    const onChange = (option: Option | null | undefined) => {
        handleChange(option ?? undefined);
    };

    const customerTitle = useMemo(() => {
        const label = selectedOption?.label;
        if (isNil(label)) return "All";
        if (label.length > 20) {
            return `${label.slice(0, 20)}...`;
        }
        return label;
    }, [selectedOption]);

    return (
        <Box>
            <Button
                onClick={(e) => {
                    setMenuAnchorEl(e.currentTarget);
                }}
                size="small"
                variant="outlined"
                sx={[styles.filterButton]}
            >
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Typography sx={styles.filterTitle}>Customer:</Typography>
                    <Typography sx={styles.filterValue}>
                        {customerTitle}
                    </Typography>
                    <ExpandMore fontSize="small" sx={{ mr: 0 }} />
                </Box>
            </Button>
            <Menu
                anchorEl={menuAnchorEl}
                id="customer-menu"
                open={Boolean(menuAnchorEl)}
                onClose={() => {
                    setMenuAnchorEl(null);
                }}
                sx={{
                    "& .MuiMenu-paper": { overflow: "visible" },
                    top: "3px",
                }}
            >
                <MenuList
                    dense
                    sx={{
                        p: 0,
                    }}
                >
                    <MenuItem
                        key="all"
                        onClick={() => onChange(undefined)}
                        sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "visible",
                            pl: "10px",
                        }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Check
                                sx={{
                                    visibility:
                                        selectedOption === undefined
                                            ? undefined
                                            : "hidden",
                                    fontSize: "14px",
                                    ml: 0,
                                    mr: "6px",
                                }}
                            />
                            <Typography sx={styles.menuText}>All</Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        key="custom"
                        sx={{
                            alignItems: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "visible",
                            pl: "10px",
                        }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Check
                                sx={{
                                    visibility: !isAllSelected
                                        ? undefined
                                        : "hidden",
                                    fontSize: "14px",
                                    ml: 0,
                                    mr: "6px",
                                }}
                            />
                            <Autocomplete
                                size="small"
                                sx={{
                                    backgroundColor: "white",
                                    width: "200px",
                                }}
                                value={selectedOption}
                                options={
                                    contactsData?.companyContacts?.map(
                                        (contact) => ({
                                            label: contact.displayName,
                                            value: contact.uuid,
                                        }),
                                    ) ?? []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        onKeyDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        size="small"
                                    />
                                )}
                                onChange={(_, option) => {
                                    onChange(option);
                                }}
                            />
                        </Stack>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default CustomerFilterButton;
