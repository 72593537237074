import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";

export type OrderImporterFormValues = {
    ordersUrl: string;
    companyUuid: string;
    userUuid: string;
};

const resolver: Resolver<OrderImporterFormValues> = async (formValues) => {
    const errors: {
        [property in keyof OrderImporterFormValues]?: {
            type: string;
            message: string;
        };
    } = {};

    const { ordersUrl, userUuid } = formValues;

    if (isNil(ordersUrl) || isEmpty(ordersUrl)) {
        errors.ordersUrl = {
            type: "required",
            message: "Orders URL is required",
        };
    }

    if (isNil(userUuid) || isEmpty(userUuid)) {
        errors.userUuid = {
            type: "required",
            message: "User UUID is required",
        };
    }

    return {
        values: formValues,
        errors,
    };
};

const useImportOrdersForm = () => {
    return useForm({ resolver });
};

export default useImportOrdersForm;
