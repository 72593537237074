import { Typography, Grid, Stack, Button } from "@mui/material";
import { sentenceCase } from "change-case";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import { useCompanyQuery } from "../../../generated/graphql";
import OrderVolumeByContactStatistics from "./order-volume-by-contact-statistics";
import OrderVolumeByDateRangeStatistics from "./order-volume-by-date-range-statistics";
import OrderVolumeByUserStatistics from "./order-volume-by-user-statistics";
import TotalOrderVolumeStatistics from "./total-order-volume-statistics";
import { StatisticsView } from "./utils";

const CompanyStatistics = ({
    companyName,
    companyUuid,
    tab,
}: {
    companyName: string;
    companyUuid: string;
    tab: StatisticsView;
}) => {
    const { data: companyData } = useCompanyQuery({
        variables: {
            uuid: companyUuid,
        },
    });

    const company = companyData?.companyByUuid;
    const logo = company?.configuration?.invoicePdfLogoUrl;

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={5} sx={{ mt: 0 }}>
                <Stack direction="row" spacing={1}>
                    {!isNil(logo) && !isEmpty(logo) && (
                        <img src={logo} alt="logo" height="60px" />
                    )}
                    <Stack>
                        <Typography>
                            {companyName} - {company?.id}
                        </Typography>
                        <Button
                            disabled={isNil(company)}
                            sx={{ fontSize: 10, p: 0, maxWidth: 10 }}
                            size="small"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    company?.uuid ?? "",
                                );
                            }}
                        >
                            Copy uuid
                        </Button>
                        <Typography variant="caption" color="text.secondary">
                            {sentenceCase(company?.defaultAddress?.city ?? "")}{" "}
                            {company?.defaultAddress?.state} | Created{" "}
                            {!isNil(company)
                                ? dayjs(company?.createdAt).format("M/DD/YY")
                                : ""}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={7} sx={{ pr: 1 }}>
                <TotalOrderVolumeStatistics companyUuid={companyUuid} />
            </Grid>
            <Grid item xs={6}>
                <OrderVolumeByContactStatistics
                    tab={tab}
                    companyUuid={companyUuid}
                />
            </Grid>
            <Grid item xs={6}>
                <OrderVolumeByDateRangeStatistics
                    tab={tab}
                    companyUuid={companyUuid}
                />
            </Grid>
            <Grid item xs={6}>
                <OrderVolumeByUserStatistics
                    tab={tab}
                    companyUuid={companyUuid}
                />
            </Grid>
        </Grid>
    );
};
export default CompanyStatistics;
