import InfoIcon from "@mui/icons-material/Info";
import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    return (
        <Grid container spacing={1}>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <Typography variant="h4">
                    Select the tool you want to use
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <Button onClick={() => navigate("/docs")}>Doc Scanning</Button>
                <Button onClick={() => navigate("/edi")}>EDI</Button>
                <Button onClick={() => navigate("/invoice-transmissions")}>
                    Invoice Emails
                </Button>
                <Button onClick={() => navigate("/canary")}>Canary</Button>
                <Button onClick={() => navigate("/code-gen")}>Code Gen</Button>
                <Button onClick={() => navigate("/test-orders")}>
                    Test Orders
                </Button>
                <Button onClick={() => navigate("/company-configurations")}>
                    Company Configurations
                </Button>
                <Button onClick={() => navigate("/crown-charges-validator")}>
                    Crown Charges Validator
                </Button>
                <Button onClick={() => navigate("/render")}>Render</Button>
                <Button onClick={() => navigate("/sftp-uploads")}>
                    SFTP Uploads
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">Crown Data Import Tools</Typography>
                <Stack direction="row" gap={1}>
                    <Button onClick={() => navigate("/contacts-importer")}>
                        Contact Importer
                    </Button>
                    <Button onClick={() => navigate("/tariff-importer")}>
                        Tariff Importer
                    </Button>
                    <Button onClick={() => navigate("/tariff-importer-v2")}>
                        Tariff Importer V2
                        <Tooltip title="For those with brains 🧠">
                            <InfoIcon />
                        </Tooltip>
                    </Button>
                    <Button onClick={() => navigate("/accessorial-importer")}>
                        Accessorial Importer
                    </Button>
                    <Button onClick={() => navigate("/orders-importer")}>
                        Orders Importer
                    </Button>
                    <Button onClick={() => navigate("/users-importer")}>
                        Users Importer
                    </Button>
                    <Button onClick={() => navigate("/drivers-importer")}>
                        Drivers Importer
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default Home;
