import Edi from "../../components/edi";
import { RequireAuth } from "../../hooks/use-auth";

const EdiPage = () => {
    return (
        <RequireAuth>
            <Edi />
        </RequireAuth>
    );
};

export default EdiPage;
