import { isEmpty, isNil } from "lodash";
import { Resolver, useForm } from "react-hook-form";
import { isNilOrEmptyString } from "shared/string";
import {
    TariffType,
    TariffZoneType,
    TariffGroupType,
    TariffGroupScope,
    RateType,
} from "../../generated/graphql";

export const NO_ZONE_GROUP = "None";

export type ImportTariffFormValues = {
    contactUuid: string;

    serviceUuids: string[];

    autoSelectAllServices: boolean;

    name: string;

    tariffZoneType: TariffZoneType;

    tariffZoneGroupId: string | null;

    unitType: TariffType;

    rateType: RateType;

    tariffGroupType: TariffGroupType;

    tariffGroupScope: TariffGroupScope;

    useActualWeight: boolean;

    startDate: Date | null | undefined;

    endDate: Date | null | undefined;

    fuelProfileUuid: string;

    tariffUrl: string;
};

const resolver: Resolver<ImportTariffFormValues> = async (formValues) => {
    const errors: {
        [property in keyof ImportTariffFormValues]?: {
            type: string;
            message: string;
        };
    } = {};

    const {
        contactUuid,
        name,
        tariffZoneType,
        unitType,
        rateType,
        tariffZoneGroupId,
        fuelProfileUuid,
        tariffUrl,
    } = formValues;

    if (isNil(contactUuid) || isEmpty(contactUuid)) {
        errors.contactUuid = {
            type: "required",
            message: "Contact is required",
        };
    }

    if (isNil(name) || isEmpty(name)) {
        errors.name = {
            type: "required",
            message: "Name is required",
        };
    }

    if (isNil(tariffZoneType) || isEmpty(tariffZoneType)) {
        errors.tariffZoneType = {
            type: "required",
            message: "Tariff Type is required",
        };
    }

    if (isNil(unitType) || isEmpty(unitType)) {
        errors.unitType = {
            type: "required",
            message: "Unit Type is required",
        };
    }

    if (isNil(rateType) || isEmpty(rateType)) {
        errors.rateType = {
            type: "required",
            message: "Rate Type is required",
        };
    }

    // if (isNil(tariffGroupType) || isEmpty(tariffGroupType)) {
    //   errors.unitType = {
    //     type: "required",
    //     message: "Tariff group Type is required",
    //   };
    // }

    if (
        isNilOrEmptyString(tariffZoneGroupId) ||
        tariffZoneGroupId === NO_ZONE_GROUP
    ) {
        errors.tariffZoneGroupId = {
            type: "required",
            message: "Zone group is required",
        };
    }

    if (isNil(fuelProfileUuid) || isEmpty(fuelProfileUuid)) {
        errors.fuelProfileUuid = {
            type: "required",
            message: "Fuel profile is required",
        };
    }

    // if (isNil(useActualWeight) || isEmpty(useActualWeight)) {
    //   errors.useActualWeight = {
    //     type: "required",
    //     message: "Use actual weight is required",
    //   };
    // }

    if (isNil(tariffUrl) || isEmpty(tariffUrl)) {
        errors.tariffUrl = {
            type: "required",
            message: "Tariff URL required",
        };
    }

    return {
        values: formValues,
        errors,
    };
};

const useImportTariffForm = () => {
    return useForm({ resolver });
};

export default useImportTariffForm;
