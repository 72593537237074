import {
    Alert,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import ReactJson from "react-json-view";
import {
    useImportCrownOrdersMutation,
    useMeQuery,
} from "../../generated/graphql";
import OrderImportConfigurationEditor from "./order-import-configuration-editor";
import useImportOrdersForm, {
    OrderImporterFormValues,
} from "./use-order-importer-form";

const OrdersImporter = () => {
    const [importCrownOrders, { loading: importCrownOrdersLoading }] =
        useImportCrownOrdersMutation();
    const { data: meData } = useMeQuery({
        fetchPolicy: "cache-first",
    });
    const company = meData?.me?.company;

    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useImportOrdersForm();

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const DEFAULT_MESSAGE = "Error importing orders";
    const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);
    const [results, setResults] = useState({});

    useEffect(() => {
        reset({
            ordersUrl: "",
            companyUuid: "",
            userUuid: "",
        });
    }, [reset]);

    const onSubmit: SubmitHandler<OrderImporterFormValues> = async (data) => {
        const { ordersUrl, userUuid } = data;

        try {
            if (isNil(company)) {
                setErrorMessage("No company found");
                setErrorVisible(true);
                return;
            }
            const response = await importCrownOrders({
                variables: {
                    importCrownOrdersInput: {
                        ordersUrl,
                        companyUuid: company.uuid,
                        userUuid,
                        finalizeOrders: false,
                    },
                },
            });
            const { errors: resErrors } = response;
            if (!isEmpty(resErrors)) {
                setErrorMessage(
                    resErrors?.map((err) => err.message).join(", ") ??
                        DEFAULT_MESSAGE,
                );
                setErrorVisible(true);
            } else {
                setResults(response.data?.importCrownOrders ?? {});
                setSuccessVisible(true);
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
            /* eslint-disable-next-line no-console */
            console.error(e);
        }
    };

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully imported orders
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">Crown Orders Importer</Typography>
                <Typography variant="h5">Company: {company?.name}</Typography>
                <Typography variant="subtitle1">
                    Please note that orders with the same CID that have already
                    been imported will be skipped.
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Controller
                    name="ordersUrl"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>
                                Orders URL (
                                <a href="https://www.loom.com/share/21e2f2439d3449dcbe49f4644b05e5ad?sid=0cfdd1ee-24a5-4f2f-b27a-01bd35c9fb72">
                                    Reports / Management gallery / Customer
                                    Daily Log
                                </a>
                                )
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                error={!isNil(errors.ordersUrl)}
                                value={value}
                                onChange={onChange}
                            />
                            {!isNil(errors.ordersUrl) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.ordersUrl.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
                <Controller
                    name="userUuid"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>
                                User UUID (Pallet team admin)
                            </InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                error={!isNil(errors.userUuid)}
                                value={value}
                                onChange={onChange}
                            />
                            {!isNil(errors.userUuid) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.userUuid.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={importCrownOrdersLoading}
                    variant="contained"
                >
                    Import Orders
                </Button>
            </Grid>
            <Grid item xs={12}>
                <ReactJson src={results} />
            </Grid>
            <OrderImportConfigurationEditor />
        </Grid>
    );
};

export default OrdersImporter;
