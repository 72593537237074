import TariffImporter from "../../components/tariff-importer/tariff-importer";
import { RequireAuth } from "../../hooks/use-auth";

const TariffImporterPage = () => {
    return (
        <RequireAuth>
            <TariffImporter />
        </RequireAuth>
    );
};

export default TariffImporterPage;
