import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import NumbersIcon from "@mui/icons-material/Numbers";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PercentIcon from "@mui/icons-material/Percent";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { sumBy } from "lodash";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useMemo, useState } from "react";
import { useOrderVolumeByCompanyReportQuery } from "../../../generated/graphql";
import DateRangePicker, { DateOption } from "../../date-range-picker";
import {
    getOrderSourceBucketByOrderSource,
    initialOrderVolumeData,
    OrderSource,
} from "./utils";

type OrderVolumeCompanyData = {
    companyUuid: string;
    companyName: string;
    [OrderSource.MANUAL]: number;
    [OrderSource.EMAIL_DOC_SCAN]: number;
    [OrderSource.UPLOADED_DOC_SCAN]: number;
    [OrderSource.API]: number;
    [OrderSource.EDI]: number;
    [OrderSource.CSV_IMPORT]: number;
    total: number;
};

const OrderVolumeByCompanyStatistics = () => {
    const [dateOption, setDateOption] = useState<DateOption>({
        startDate: dayjs(new Date()).subtract(30, "days").toDate(),
        endDate: null,
    });
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [showPercentage, setShowPercentage] = useState<boolean>(false);
    const { data: orderVolumeByCompanyData, loading } =
        useOrderVolumeByCompanyReportQuery({
            variables: {
                orderVolumeByCompanyInput: {
                    startDate: dateOption.startDate,
                    endDate: dateOption.endDate,
                },
            },
        });

    const orderVolumeData = useMemo(() => {
        return orderVolumeByCompanyData?.orderVolumeByCompanyReport.map(
            (companyData) => {
                const data: OrderVolumeCompanyData = {
                    ...initialOrderVolumeData,
                    ...companyData,
                };
                data.total = sumBy(
                    Object.values(OrderSource),
                    (source) =>
                        getOrderSourceBucketByOrderSource(
                            source,
                            companyData.sourceAmounts,
                        )?.totalCount ?? 0,
                );
                Object.values(OrderSource).forEach((source) => {
                    data[source] =
                        getOrderSourceBucketByOrderSource(
                            source,
                            companyData.sourceAmounts,
                        )?.totalCount ?? 0;
                    if (showPercentage) {
                        const roundedPercentage = Math.round(
                            (data[source] / data.total) * 100,
                        );
                        data[source] = Number.isNaN(roundedPercentage)
                            ? 0
                            : roundedPercentage;
                    }
                });
                return data;
            },
        );
    }, [orderVolumeByCompanyData, showPercentage]);

    const header = (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                border: 1,
                borderBottom: 0,
                borderColor: "#E9ECEF",
                backgroundColor: "#f8f9fa",
                p: 0.5,
            }}
        >
            <Stack>
                <Typography sx={{ fontWeight: "bold" }}>
                    Order volume
                </Typography>
                <Typography variant="caption">(by company)</Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                <DateRangePicker
                    dateOption={dateOption}
                    onChange={(newDateOption) => setDateOption(newDateOption)}
                />
                <Divider orientation="vertical" flexItem />
                <Typography sx={{ fontWeight: "bold" }}>Metric</Typography>
                <Tooltip title="Counts">
                    <IconButton
                        disabled={!showPercentage}
                        size="small"
                        onClick={() => {
                            setShowPercentage(false);
                        }}
                    >
                        <NumbersIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Percentage of total">
                    <IconButton
                        disabled={showPercentage}
                        size="small"
                        onClick={() => {
                            setShowPercentage(true);
                        }}
                    >
                        <PercentIcon />
                    </IconButton>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    size="small"
                    onClick={() => {
                        setIsExpanded((prev) => !prev);
                    }}
                >
                    {isExpanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                </IconButton>
            </Stack>
        </Stack>
    );

    const sourceTemplate = (
        data: OrderVolumeCompanyData,
        options: ColumnBodyOptions,
    ) => {
        return (
            <span>
                {data[options.field as OrderSource] ?? ""}
                {showPercentage ? "%" : ""}
            </span>
        );
    };

    return (
        <div
            style={
                isExpanded
                    ? {
                          position: "absolute",
                          zIndex: 10000,
                          top: 0,
                          left: 0,
                          width: "100vw",
                          height: "100vh",
                          backgroundColor: "white",
                      }
                    : {}
            }
        >
            {header}
            <DataTable
                key={`open-${isExpanded}`}
                value={orderVolumeData}
                sortMode="multiple"
                removableSort
                showGridlines
                rows={10}
                loading={loading}
                dataKey="id"
                emptyMessage="No data"
                size="small"
                scrollable
                scrollHeight={isExpanded ? "100vh" : "325px"}
                stripedRows
                virtualScrollerOptions={{ itemSize: 25 }}
            >
                <Column
                    field="companyName"
                    header="Company"
                    sortable
                    filter
                    filterPlaceholder="Search by company name"
                    style={{ width: "35%" }}
                />
                {Object.values(OrderSource).map((source) => (
                    <Column
                        key={source}
                        header={source}
                        field={source}
                        body={sourceTemplate}
                        sortable
                    />
                ))}
                <Column header="Total" field="total" sortable />
            </DataTable>
        </div>
    );
};

export default OrderVolumeByCompanyStatistics;
