import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {
    SftpUploadsQuery,
    SftpUploadStatus,
    useRetrySftpUploadsMutation,
} from "../generated/graphql";

interface SFTPUploadsTableProps {
    uploads?: SftpUploadsQuery["sftpUploads"]["edges"];
    refetch: () => void;
}

const SFTPUploadsTable = ({ uploads, refetch }: SFTPUploadsTableProps) => {
    const errorMessage = (error: string | null | undefined) => {
        if (error === null) {
            return "None";
        }

        return error;
    };
    const [retrySftpUpload] = useRetrySftpUploadsMutation();
    const handleRetry = (uuid: string) => {
        retrySftpUpload({
            variables: {
                retrySftpUploadsInput: { sftpUploadUuid: uuid },
            },
        })
            .then((response) => {
                refetch();
                /* eslint-disable-next-line no-console */
                console.log("Upload retried successfully:", response);
            })
            .catch((error) => {
                /* eslint-disable-next-line no-console */
                console.error("Error retrying upload:", error);
            });
    };

    return (
        <Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>SFTP Upload Type</TableCell>
                            <TableCell>Sent At</TableCell>
                            <TableCell>Shipper BOL #</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Error Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {uploads?.map(({ node: sftpUpload }) => {
                            return (
                                <TableRow key={sftpUpload.uuid}>
                                    <TableCell>
                                        {sftpUpload.company.name}
                                    </TableCell>
                                    <TableCell>
                                        {sftpUpload.contact.displayName}
                                    </TableCell>
                                    <TableCell>{sftpUpload.type}</TableCell>
                                    <TableCell>
                                        {dayjs(sftpUpload.createdAt).format(
                                            "YYYY-MM-DD HH:mm:ss",
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            sftpUpload.order
                                                ?.standardOrderFields
                                                .shipperBillOfLadingNumber
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {sftpUpload.order?.uuid}
                                    </TableCell>
                                    <TableCell>{sftpUpload.status}</TableCell>
                                    <TableCell>
                                        {errorMessage(sftpUpload?.error)}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            disabled={
                                                sftpUpload.status !==
                                                SftpUploadStatus.Failed
                                            }
                                            variant="outlined"
                                            onClick={() =>
                                                handleRetry(sftpUpload.uuid)
                                            }
                                        >
                                            Retry EDI
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};
export default SFTPUploadsTable;
