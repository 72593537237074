import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// import ViewEmailPayloadDialog from "./view-email-payload-dialog";
import { isEmpty, isNil } from "lodash";
import { useState } from "react";
import { InvoiceEmailsApiLogsQuery } from "../../generated/graphql";
import ViewEmailResponseDialog from "./view-email-response-dialog";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface InvoiceEmailsTableProps {
    apiLogsData?: InvoiceEmailsApiLogsQuery;
}
const InvoiceEmailsTable = ({ apiLogsData }: InvoiceEmailsTableProps) => {
    // const [emailBodyModalOpen, setEmailBodyModalOpen] = useState(false);
    const [emailResponseBodyModalOpen, setEmailResponseBodyModalOpen] =
        useState(false);
    // const [emailBody, setEmailBody] = useState("");
    const [emailResponse, setEmailResponse] = useState("");

    return (
        <Grid container spacing={2}>
            {/* <ViewEmailPayloadDialog */}
            {/*  open={emailBodyModalOpen} */}
            {/*  onClose={() => setEmailBodyModalOpen(false)} */}
            {/*  payload={emailBody} */}
            {/* /> */}
            <ViewEmailResponseDialog
                open={emailResponseBodyModalOpen}
                onClose={() => setEmailResponseBodyModalOpen(false)}
                payload={emailResponse}
            />
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableCell>Email Attempted At</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Contact</TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell>Invoice</TableCell>
                        <TableCell>Emails</TableCell>
                        <TableCell>Email Response</TableCell>
                        <TableCell>Email Response Status Code</TableCell>
                    </TableHead>
                    <TableBody>
                        {apiLogsData?.invoiceEmailsApiLogs?.apiLogConnection?.edges?.map(
                            ({ node: apiLog }) => {
                                const emailResponseStatus =
                                    apiLog?.emailTransaction
                                        ?.emailResponseStatus;
                                const documentUrl =
                                    apiLog?.emailTransaction?.document
                                        ?.preSignedGetUrl;
                                const emails =
                                    apiLog?.emailTransaction?.emails ?? [];
                                const timeZone =
                                    apiLog?.emailTransaction?.company?.timeZone;

                                const isSuccessfulTransaction =
                                    !isNil(emailResponseStatus) &&
                                    emailResponseStatus >= 200 &&
                                    emailResponseStatus < 300;

                                const createdAt =
                                    apiLog?.emailTransaction?.createdAt;
                                const emailSentAt =
                                    !isNil(createdAt) && !isNil(timeZone)
                                        ? dayjs(createdAt)
                                              .tz(timeZone)
                                              .format(
                                                  `MM/DD/YYYY [at] HH:mma z`,
                                              )
                                        : "-";

                                return (
                                    <TableRow
                                        key={apiLog.uuid}
                                        sx={{
                                            background: isSuccessfulTransaction
                                                ? "#dff7e2"
                                                : "#f7dfdf",
                                        }}
                                    >
                                        <TableCell>{emailSentAt}</TableCell>
                                        <TableCell>
                                            {apiLog?.emailTransaction?.company
                                                ?.name ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {apiLog?.emailTransaction?.contact
                                                ?.displayName ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                disabled={isEmpty(documentUrl)}
                                            >
                                                <a
                                                    href={documentUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    View
                                                </a>
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            {apiLog?.emailTransaction?.invoice
                                                ?.name ?? "-"}
                                        </TableCell>
                                        <TableCell>
                                            {!isEmpty(emails)
                                                ? emails.join(", ")
                                                : "-"}
                                        </TableCell>
                                        {/* <TableCell> */}
                                        {/*  <Button */}
                                        {/*    variant="outlined" */}
                                        {/*    size="small" */}
                                        {/*    onClick={() => { */}
                                        {/*      const payload = apiLog?.payload; */}
                                        {/*      if (!isNil(payload)) { */}
                                        {/*        setEmailBody(payload); */}
                                        {/*        setEmailBodyModalOpen(true); */}
                                        {/*      } */}
                                        {/*    }} */}
                                        {/*    disabled={isEmpty(apiLog?.payload)} */}
                                        {/*  > */}
                                        {/*    View */}
                                        {/*  </Button> */}
                                        {/* </TableCell> */}
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    const response =
                                                        apiLog?.emailTransaction
                                                            ?.emailResponse;
                                                    if (!isNil(response)) {
                                                        setEmailResponse(
                                                            response,
                                                        );
                                                        setEmailResponseBodyModalOpen(
                                                            true,
                                                        );
                                                    }
                                                }}
                                                disabled={isEmpty(
                                                    apiLog?.emailTransaction
                                                        ?.emailResponse,
                                                )}
                                            >
                                                View
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {emailResponseStatus}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default InvoiceEmailsTable;
