/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Stack, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import {
    StructuredExtractionSchemaOverrideDocument,
    useStructuredExtractionSchemaOverrideQuery,
    useUpsertStructuredExtractionSchemaOverrideMutation,
} from "../../../../generated/graphql";
import KeyOverrides from "./key-overrides";
import PromptOverrides from "./prompt-overrides";
import StructuredExtractionSchemaExclusions from "./structured-extraction-schema-exclusions";

const StructuredExtractionSchemaEditor = ({
    schemaUuid,
}: {
    schemaUuid: string;
}) => {
    const [deadlineDateKeys, setDeadlineDateKeys] = useState<string[]>([]);
    const [specialInstructionsKeys, setSpecialInstructionsKeys] = useState<
        string[]
    >([]);
    const [schemaOverride, setSchemaOverride] = useState<any>();
    const [schemaExclusions, setSchemaExclusions] = useState<any>();
    const { data: structuredExtractionSchemaOverrideData } =
        useStructuredExtractionSchemaOverrideQuery({
            variables: {
                schemaUuid,
            },
        });
    const [upsertStructuredExtractionSchemaOverride, { loading }] =
        useUpsertStructuredExtractionSchemaOverrideMutation();

    useEffect(() => {
        const override =
            structuredExtractionSchemaOverrideData?.getStructuredExtractionSchemaOverride;
        const schemaOverrideString = override?.schemaOverride;
        const schemaExclusionsString = override?.schemaExclusions;
        const _deadlineDateKeys = override?.deadlineDateKeys;
        const _specialInstructionsKeys = override?.specialInstructionsKeys;
        if (!isNil(_deadlineDateKeys)) {
            setDeadlineDateKeys(_deadlineDateKeys);
        }
        if (!isNil(_specialInstructionsKeys)) {
            setSpecialInstructionsKeys(_specialInstructionsKeys);
        }
        if (!isNil(schemaOverrideString)) {
            setSchemaOverride(JSON.parse(schemaOverrideString));
        }
        if (!isNil(schemaExclusionsString)) {
            setSchemaExclusions(JSON.parse(schemaExclusionsString));
        }
    }, [structuredExtractionSchemaOverrideData]);

    const onSave = async () => {
        const res = await upsertStructuredExtractionSchemaOverride({
            variables: {
                upsertStructuredExtractionSchemaOverrideInput: {
                    schemaUuid,
                    schemaOverride: JSON.stringify(schemaOverride),
                    schemaExclusions: JSON.stringify(schemaExclusions),
                    deadlineDateKeys: deadlineDateKeys ?? [],
                    specialInstructionsKeys: specialInstructionsKeys ?? [],
                },
            },
            refetchQueries: [StructuredExtractionSchemaOverrideDocument],
        });
        if (!isNil(res.errors)) {
            alert(`An error occurred: ${res.errors[0]?.message}`);
        }
    };

    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pr: 1 }}
            >
                <Typography sx={{ fontWeight: "bold" }}>
                    GPT Overrides
                </Typography>
                <Button disabled={loading} onClick={onSave} variant="contained">
                    Save
                </Button>
            </Stack>
            <Stack spacing={1}>
                <KeyOverrides
                    keys={deadlineDateKeys}
                    setKeys={setDeadlineDateKeys}
                    label="Deadline Date"
                />
                <KeyOverrides
                    keys={specialInstructionsKeys}
                    setKeys={setSpecialInstructionsKeys}
                    label="Special Instructions"
                />
                <Divider />
                {!isNil(schemaExclusions) && (
                    <StructuredExtractionSchemaExclusions
                        schemaExclusions={schemaExclusions}
                        setSchemaExclusions={setSchemaExclusions}
                    />
                )}
                <Divider />
                {!isNil(schemaOverride) && (
                    <PromptOverrides
                        schemaOverride={schemaOverride}
                        setSchemaOverride={setSchemaOverride}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default React.memo(StructuredExtractionSchemaEditor);
