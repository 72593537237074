import { isNil } from "lodash";
import { useState } from "react";
import Modal from "react-modal";
import { chunkArray } from "../../../utils/array";
import { sleep } from "../../../utils/sleep";

type UploadFileMatcherModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleUpload: ({ file }: { file: File }) => Promise<void>;
};

/**
 * Convert bytes to megabytes, truncated to 2 decimal places
 * @param bytes
 */
const byteToMegabytes = (bytes: number) => {
    return Math.trunc((100 * bytes) / 1000000) / 100;
};

const UploadFileModal = ({
    isOpen,
    handleUpload,
    onClose,
}: UploadFileMatcherModalProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const handleClose = () => {
        setFiles([]);
        setUploading(false);
        onClose();
    };

    return (
        <Modal
            contentLabel="Upload files"
            style={{
                content: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                },
            }}
            isOpen={isOpen}
            onRequestClose={handleClose}
            ariaHideApp={false}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "black",
                }}
            >
                <h3>Upload files</h3>
                <input
                    multiple
                    type="file"
                    onChange={(e) => {
                        if (isNil(e.target.files)) {
                            setFiles([]);
                        } else {
                            setFiles(Array.from(e.target.files));
                        }
                    }}
                />
                {files.map((file) => {
                    return (
                        <div key={file.name}>
                            File: {file?.name} ({byteToMegabytes(file?.size)}{" "}
                            mb)
                        </div>
                    );
                })}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                <button
                    disabled={files.length === 0 || uploading}
                    style={{ marginRight: "10px", height: "30px" }}
                    onClick={async () => {
                        setUploading(true);
                        for (const chunk of chunkArray(files, 3)) {
                            const promises = [];
                            // Pseudo-parallelize. Can't fully parallelize because the
                            // mutation seems to share state so need to stagger. Need to
                            // chunk to avoid getting rate-limited by AWS
                            for (const file of chunk) {
                                promises.push(handleUpload({ file }));
                                await sleep(1000);
                            }
                            await Promise.all(promises);
                        }
                        handleClose();
                        setUploading(false);
                    }}
                >
                    Upload files
                </button>
            </div>
        </Modal>
    );
};

export default UploadFileModal;
