import { Button, Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import { filterNotNil } from "../utils/array";

export type DateOption = {
    startDate: Date | null;
    endDate: Date | null;
};

const getDateOptionString = ({ startDate, endDate }: DateOption) => {
    const now = new Date();
    if (isNil(endDate) && isNil(startDate)) {
        return "All time";
    }
    if (isNil(endDate)) {
        return `Last ${dayjs(now).diff(startDate, "days")} days`;
    }
    return `${dayjs(startDate).format("M/DD")}-${dayjs(endDate).format("M/DD/YY")}`;
};

const DateRangePicker = ({
    dateOption,
    onChange,
    excludeAll = false,
}: {
    dateOption: DateOption;
    onChange: (newDateOption: DateOption) => void;
    excludeAll?: boolean;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rangeSelected, setRangeSelected] = useState(false);
    const onClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                size="small"
                onClick={(e) => {
                    if (!rangeSelected) {
                        setAnchorEl(e.currentTarget);
                    }
                }}
            >
                {rangeSelected && (
                    <span
                        style={{ marginRight: 5 }}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                        }}
                    >
                        Range
                    </span>
                )}
                {rangeSelected ? (
                    <Calendar
                        style={{ width: "100px" }}
                        value={filterNotNil([
                            dateOption.startDate,
                            dateOption.endDate,
                        ])}
                        onChange={(e) => {
                            onChange({
                                startDate: e.value?.[0] ?? null,
                                endDate: e.value?.[1] ?? null,
                            });
                            e.stopPropagation();
                        }}
                        dateFormat="m/d/y"
                        numberOfMonths={3}
                        selectionMode="range"
                        readOnlyInput
                        hideOnRangeSelection
                    />
                ) : (
                    getDateOptionString(dateOption)
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={!isNil(anchorEl)}
                onClose={onClose}
                sx={{ zIndex: 10000 }}
            >
                {excludeAll !== true && (
                    <MenuItem
                        onClick={() => {
                            setRangeSelected(false);
                            onChange({
                                startDate: null,
                                endDate: null,
                            });
                            onClose();
                        }}
                    >
                        All time
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setRangeSelected(false);
                        onChange({
                            startDate: dayjs(new Date())
                                .subtract(1, "days")
                                .startOf("day")
                                .toDate(),
                            endDate: null,
                        });
                        onClose();
                    }}
                >
                    Last day
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setRangeSelected(false);
                        onChange({
                            startDate: dayjs(new Date())
                                .subtract(7, "days")
                                .startOf("day")
                                .toDate(),
                            endDate: null,
                        });
                        onClose();
                    }}
                >
                    Last 7 days
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setRangeSelected(false);
                        onChange({
                            startDate: dayjs(new Date())
                                .subtract(30, "days")
                                .startOf("day")
                                .toDate(),
                            endDate: null,
                        });
                        onClose();
                    }}
                >
                    Last 30 days
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setRangeSelected(false);
                        onChange({
                            startDate: dayjs(new Date())
                                .subtract(90, "days")
                                .startOf("day")
                                .toDate(),
                            endDate: null,
                        });
                        onClose();
                    }}
                >
                    Last 90 days
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setRangeSelected(true);
                    }}
                >
                    Range
                </MenuItem>
            </Menu>
        </>
    );
};

export default DateRangePicker;
