import { isNil } from "lodash";
import { useEffect, useState } from "react";
import Modal from "react-modal";

type EditFingerprintAnchorModalProps = {
    isOpen: boolean;
    onClose: () => void;
    text: string;
    substring: string | null | undefined;
    handleSave: ({ substring }: { substring: string }) => void;
};

const EditFingerprintAnchorModal = ({
    isOpen,
    onClose,
    handleSave,
    text,
    substring: substring_,
}: EditFingerprintAnchorModalProps) => {
    const [substring, setSubstring] = useState<string>("");

    useEffect(() => {
        if (isOpen && !isNil(substring_)) {
            setSubstring(substring_);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Modal
            contentLabel="Edit fingerprint anchor"
            style={{
                content: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                },
            }}
            isOpen={isOpen}
            onRequestClose={onClose}
            ariaHideApp={false}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    color: "black",
                }}
            >
                <h3>Text</h3>
                <span>{text}</span>
                <h3>Substring (optional)</h3>
                <input
                    onChange={(e) => setSubstring(e.target.value)}
                    value={substring}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            >
                <button
                    style={{ marginRight: "10px", height: "30px" }}
                    onClick={() => {
                        if (!text.includes(substring)) {
                            alert("Substring must be included in text");
                            return;
                        }
                        if (
                            substring.trim().length > 0 &&
                            substring.trim().length < 3
                        ) {
                            alert("Substring must be at least 3 characters");
                            return;
                        }
                        handleSave({ substring: substring.trim() });
                    }}
                >
                    Save changes
                </button>
                <button onClick={onClose}>Discard</button>
            </div>
        </Modal>
    );
};

export default EditFingerprintAnchorModal;
