import {
    ApolloClient,
    ApolloLink,
    defaultDataIdFromObject,
    from,
    HttpLink,
    InMemoryCache,
    split,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

const cache = new InMemoryCache({
    dataIdFromObject: (responseObject) => {
        return (
            (responseObject.uuid as string) ??
            defaultDataIdFromObject(responseObject)
        );
    },
});

const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/graphql`;

const operationsToNotBatch = [
    "orderVolumeByDateRangeReport",
    "orderVolumeByContactReport",
    "allScannedDocuments",
    "schemas",
];

const apolloClient = new ApolloClient({
    cache,
    credentials: "include",
    uri: apiUrl,
    link: ApolloLink.from([
        split(
            (operation) =>
                operationsToNotBatch.includes(operation.operationName),
            from([
                new HttpLink({
                    credentials: "include",
                    uri: apiUrl,
                }),
            ]),
            from([new BatchHttpLink({ credentials: "include", uri: apiUrl })]),
        ),
    ]),
    // disable apollo cache
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    },
});

export default apolloClient;
