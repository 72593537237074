import { Card, CardContent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../components/common/use-on-screen";
import { RenderServiceWithDeploysEntity } from "../../generated/graphql";
import RenderServiceTable from "./render-service-table";

const RenderServiceCard = ({
    service,
}: {
    service: RenderServiceWithDeploysEntity;
}) => {
    const cardRef = useRef(null);
    const isVisible = useOnScreen(cardRef);
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    useEffect(() => {
        if (isVisible) {
            setShouldRender(true);
        }
    }, [isVisible]);

    return (
        <Card variant="outlined" sx={{ minHeight: 400 }} ref={cardRef}>
            <CardContent sx={{ pt: 1, px: 1 }}>
                {shouldRender && <RenderServiceTable service={service} />}
            </CardContent>
        </Card>
    );
};

export default RenderServiceCard;
