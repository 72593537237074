import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    InputAdornment,
} from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useInvoiceEmailsApiLogsLazyQuery } from "../../generated/graphql";
import CompanyFilterButton from "../common/company-filter-button";
import CustomerFilterButton, { Option } from "../common/customer-filter-button";
import EmailResponseStatusFilterButton from "./email-response-status-filter-button";
import InvoiceEmailsTable from "./invoice-emails-table";

const API_LOGS_PAGE_SIZE = 15;

const InvoiceEmails = () => {
    const [companyOption, setCompanyOption] = useState<Option | undefined>();
    const [customerOption, setCustomerOption] = useState<Option | undefined>();
    const [emailResponseStatusOption, setEmailResponseStatusOption] = useState<
        Option | undefined
    >();
    const [canGoPrevious, setCanGoPrevious] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const [searchText, setSearchText] = useState<string | undefined>();

    const [getApiLogs, { data: apiLogsData, loading: apiLogsLoading }] =
        useInvoiceEmailsApiLogsLazyQuery();

    const companyUuid = companyOption?.value;

    const handleCompanyChange = (option: Option | undefined) => {
        setCompanyOption(option);
    };

    const handleCustomerChange = (option: Option | undefined) => {
        setCustomerOption(option);
    };

    const handleEmailResponseStatusChange = (option: Option | undefined) => {
        setEmailResponseStatusOption(option);
    };

    /**
     * Graphql pagination args
     * https://relay.dev/graphql/connections.htm
     */
    interface FetchApiLogsParams {
        first?: number | null | undefined;
        after?: string | null | undefined;
        last?: number | null | undefined;
        before?: string | null | undefined;
    }

    const fetchApiLogs = async ({
        first,
        after,
        last,
        before,
    }: FetchApiLogsParams) => {
        await getApiLogs({
            variables: {
                findInvoiceEmailsApiLogsInput: {
                    first,
                    after,
                    last,
                    before,
                    companyUuid: companyOption?.value,
                    contactUuid: customerOption?.value,
                    isSuccessfulEmailTransaction:
                        emailResponseStatusOption?.value === "SUCCESS"
                            ? true
                            : undefined,
                    isFailedEmailTransaction:
                        emailResponseStatusOption?.value === "FAILED"
                            ? true
                            : undefined,
                    searchText,
                },
            },
        });
    };

    useEffect(() => {
        fetchApiLogs({ first: API_LOGS_PAGE_SIZE });
    }, [companyOption, customerOption, emailResponseStatusOption]);

    const paginationButtons = (
        <Stack direction="row" justifyContent="flex-end">
            <Button
                size="small"
                onClick={async () => {
                    await fetchApiLogs({
                        last: API_LOGS_PAGE_SIZE,
                        before: apiLogsData?.invoiceEmailsApiLogs
                            ?.apiLogConnection.pageInfo.startCursor,
                    });
                    setCanGoPrevious(false);
                    setCanGoNext(true);
                }}
                disabled={
                    apiLogsLoading ||
                    (apiLogsData?.invoiceEmailsApiLogs?.apiLogConnection
                        .pageInfo.hasPreviousPage !== true &&
                        !canGoPrevious)
                }
                variant="contained"
                sx={{ mr: 2 }}
                startIcon={<ArrowLeft />}
            >
                Previous page
            </Button>
            <Button
                size="small"
                onClick={async () => {
                    await fetchApiLogs({
                        first: API_LOGS_PAGE_SIZE,
                        after: apiLogsData?.invoiceEmailsApiLogs
                            ?.apiLogConnection.pageInfo.endCursor,
                    });
                    setCanGoPrevious(true);
                    setCanGoNext(false);
                }}
                disabled={
                    apiLogsLoading ||
                    (apiLogsData?.invoiceEmailsApiLogs?.apiLogConnection
                        .pageInfo.hasNextPage !== true &&
                        !canGoNext)
                }
                variant="contained"
                endIcon={<ArrowRight />}
            >
                Next page
            </Button>
        </Stack>
    );

    return (
        <Grid container spacing={2} sx={{ padding: 10 }}>
            <Grid item xs={4}>
                <Stack direction="row" gap={1} alignItems="center">
                    <TextField
                        size="small"
                        label="Search logs"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                fetchApiLogs({ first: API_LOGS_PAGE_SIZE });
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {apiLogsLoading === true && (
                                        <CircularProgress size={15} />
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                            fetchApiLogs({ first: API_LOGS_PAGE_SIZE })
                        }
                    >
                        Search
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" gap={1} alignItems="center">
                    <CompanyFilterButton
                        selectedOption={companyOption}
                        handleChange={handleCompanyChange}
                    />
                    {!isNil(companyUuid) && (
                        <CustomerFilterButton
                            companyUuid={companyUuid}
                            selectedOption={customerOption}
                            handleChange={handleCustomerChange}
                        />
                    )}
                    <EmailResponseStatusFilterButton
                        selectedOption={emailResponseStatusOption}
                        handleChange={handleEmailResponseStatusChange}
                    />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {paginationButtons}
            </Grid>
            {apiLogsLoading === true ? (
                <CircularProgress />
            ) : (
                <Grid item xs={12}>
                    <InvoiceEmailsTable apiLogsData={apiLogsData} />
                </Grid>
            )}
        </Grid>
    );
};

export default InvoiceEmails;
