/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Link, TableCell, TableRow } from "@mui/material";
import { sentenceCase } from "change-case";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { RefreshIcon } from "primereact/icons/refresh";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import {
    ScannedOrderResultsForAdminQuery,
    ScannedOrderResultStatus,
} from "../../../../generated/graphql";
import DocViewerModal from "../doc-viewer-modal";
import useSchemaStore from "../schema-store";
import { ScanSchemaDocumentJob } from "../types";
import useScannedOrders from "../use-scanned-orders";
import ScannedOrderResultDialog from "./scanned-order-result-dialog";

const stringDateRegexPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cleanOrderObject(obj: any, keysToRemove: string[]): any {
    if (Array.isArray(obj))
        return obj.map((item) => cleanOrderObject(item, keysToRemove));

    if (typeof obj === "object" && obj !== null) {
        return Object.keys(obj).reduce((previousValue, key) => {
            if (
                dayjs(obj[key] ?? "").isValid() &&
                stringDateRegexPattern.test(obj[key] ?? "")
            ) {
                return {
                    ...previousValue,
                    [key]: dayjs(obj[key]).format("MM/DD/YY"),
                };
            }
            return keysToRemove.includes(key)
                ? previousValue
                : {
                      ...previousValue,
                      [key]: cleanOrderObject(obj[key], keysToRemove),
                  };
        }, {});
    }

    return obj;
}

const ScanJobTableRow = ({
    scannedOrderResult,
    scanSchemaDocumentJob,
}: {
    scannedOrderResult: ScannedOrderResultsForAdminQuery["scannedOrderResultsForAdmin"]["edges"]["0"]["node"];
    scanSchemaDocumentJob: ScanSchemaDocumentJob | undefined;
}) => {
    const [docUrlToView, setDocUrlToView] = useState<string | undefined>(
        undefined,
    );
    const [selectedScanSchemaDocumentJob, setSelectedScanSchemaDocumentJob] =
        useState<ScanSchemaDocumentJob | undefined>(undefined);
    const [resultDocUrls, setResultDocUrls] = useState<string[] | undefined>();
    const [jsonResult, setJsonResult] = useState<any | undefined>();
    const deleteScanSchemaDocumentJob = useSchemaStore(
        (state) => state.deleteScanSchemaDocumentJob,
    );
    const [isHovered, setHoveredScannedOrderUuid, setIsViewingScanResult] =
        useSchemaStore(
            (state) => [
                state.hoveredScannedOrderUuid ===
                    scannedOrderResult.scannedOrder.uuid,
                state.setHoveredScannedOrderUuid,
                state.setIsViewingScanResult,
            ],
            shallow,
        );
    const { createScannedOrderFromSchema } = useScannedOrders();

    const onView = () => {
        setResultDocUrls(
            scannedOrderResult.order?.documents.map((d) => d.preSignedGetUrl),
        );
        setJsonResult(
            cleanOrderObject(scannedOrderResult, ["__typename", "documents"]),
        );
        setSelectedScanSchemaDocumentJob(scanSchemaDocumentJob);
        setIsViewingScanResult(true);
    };

    return (
        <TableRow
            key={scannedOrderResult.uuid}
            sx={{ backgroundColor: isHovered ? "#f5f5f5" : undefined }}
            onMouseEnter={() => {
                setHoveredScannedOrderUuid(
                    scannedOrderResult.scannedOrder.uuid,
                );
            }}
            onMouseLeave={() => {
                setHoveredScannedOrderUuid(undefined);
            }}
        >
            <TableCell>
                {dayjs(scannedOrderResult.createdAt).format(
                    "MM/DD/YYYY hh:mm A",
                )}
            </TableCell>
            <TableCell>{scanSchemaDocumentJob?.schema?.name}</TableCell>
            <TableCell>
                {scanSchemaDocumentJob?.schemaDocument?.company?.name}
            </TableCell>
            <TableCell sx={{ cursor: "pointer" }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                    onClick={() => {
                        setDocUrlToView(
                            scannedOrderResult.preSignedGetUrl ?? undefined,
                        );
                        setIsViewingScanResult(true);
                    }}
                >
                    {scannedOrderResult.filename}
                </Link>
            </TableCell>
            <TableCell>
                {
                    scannedOrderResult.order?.standardOrderFields
                        .shipperBillOfLadingNumber
                }
            </TableCell>
            <TableCell>
                {scannedOrderResult.status === ScannedOrderResultStatus.Failed
                    ? sentenceCase(scannedOrderResult.failureReason ?? "")
                    : sentenceCase(scannedOrderResult.status)}
            </TableCell>
            <TableCell>
                <Button onClick={onView}>View</Button>
            </TableCell>
            <TableCell>
                {!isNil(scanSchemaDocumentJob?.schema) && (
                    <IconButton
                        onClick={() => {
                            createScannedOrderFromSchema({
                                schema: scanSchemaDocumentJob?.schema,
                                schemaDocument:
                                    scanSchemaDocumentJob?.schemaDocument,
                            });
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                )}
            </TableCell>
            <TableCell>
                <IconButton
                    size="small"
                    onClick={() => {
                        if (!isNil(scanSchemaDocumentJob)) {
                            deleteScanSchemaDocumentJob(
                                scanSchemaDocumentJob.id,
                            );
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </TableCell>
            <ScannedOrderResultDialog
                schema={scanSchemaDocumentJob?.schema}
                jsonResult={jsonResult}
                resultDocUrls={resultDocUrls}
                onClose={() => {
                    setJsonResult(undefined);
                    setResultDocUrls(undefined);
                    setIsViewingScanResult(false);
                }}
                onRetrigger={() => {
                    if (!isNil(selectedScanSchemaDocumentJob)) {
                        createScannedOrderFromSchema({
                            schema: selectedScanSchemaDocumentJob?.schema,
                            schemaDocument:
                                selectedScanSchemaDocumentJob?.schemaDocument,
                        });
                    }
                    setSelectedScanSchemaDocumentJob(undefined);
                }}
            />
            <DocViewerModal
                labelText={scanSchemaDocumentJob?.schema?.name}
                open={!isNil(docUrlToView)}
                onClose={() => {
                    setDocUrlToView(undefined);
                    setIsViewingScanResult(false);
                }}
                preSignedGetUrl={docUrlToView}
            />
        </TableRow>
    );
};

export default ScanJobTableRow;
