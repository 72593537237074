import { useMemo } from "react";
import { buildUserPermissionsList } from "shared/roles";
import { useUserRolesQuery } from "../generated/graphql";

const useUserRoles = () => {
    const {
        data: userRolesData,
        loading: userRolesLoading,
        refetch: refetchUserRoles,
    } = useUserRolesQuery({
        fetchPolicy: "cache-and-network",
    });

    const userPermissions = useMemo(() => {
        return buildUserPermissionsList(userRolesData?.userRoles ?? []);
    }, [userRolesData]);

    return {
        userPermissions,
        userRolesLoading,
        refetchUserRoles,
    };
};

export default useUserRoles;
