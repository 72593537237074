import { Box, Button } from "@mui/material";
import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import {
    SchemaRunType,
    useCreateSchemaTestSnapshotMutation,
    useSchemaRunQuery,
} from "../generated/graphql";

export const SchemaRunPage = () => {
    const { uuid } = useParams();
    const { data, loading, refetch } = useSchemaRunQuery({
        variables: {
            uuid: uuid ?? "",
        },
    });
    const [
        createSchemaTestSnapshot,
        { loading: createSchemaTestSnapshotLoading },
    ] = useCreateSchemaTestSnapshotMutation();

    if (loading !== false) {
        return <div>Loading...</div>;
    }
    if (isNil(data)) {
        return <div>No data</div>;
    }

    const split = data.schemaRun?.key.split("/") ?? [];
    const filename = split[split.length - 1];

    const handleCreateSchemaTestSnapshot = async () => {
        await createSchemaTestSnapshot({
            variables: {
                createSchemaTestSnapshotInput: {
                    uuid: uuid ?? "",
                },
            },
        });
        await refetch();
    };

    return (
        <div
            style={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                fontSize: "24px",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                padding: "20px",
                textAlign: "center",
            }}
        >
            <h3>Schema: {data.schemaRun?.schema?.name}</h3>
            <h3>File: {filename}</h3>
            <br />
            <h2>Fingerprint anchors matched</h2>
            <br />
            <table>
                <tr>
                    <th>text</th>
                    <th>substring</th>
                </tr>
                {data.schemaRun?.fingerprintAnchorMatches.map(
                    (fingerprintAnchorMatch) => {
                        return (
                            <tr key={fingerprintAnchorMatch.uuid}>
                                <td>{fingerprintAnchorMatch.text}</td>
                                <td>{fingerprintAnchorMatch.substring}</td>
                            </tr>
                        );
                    },
                )}
            </table>
            <br />
            <h2>Transform anchors matched</h2>
            <br />
            <table>
                <tr>
                    <th>text</th>
                </tr>
                {data.schemaRun?.transformAnchorMatches.map(
                    (transformAnchorMatch) => {
                        return (
                            <tr key={transformAnchorMatch.uuid}>
                                <td>{transformAnchorMatch.text}</td>
                            </tr>
                        );
                    },
                )}
            </table>
            <br />
            <h2>Fields extracted</h2>
            <br />
            <table>
                <tr>
                    <th>field</th>
                    <th>value</th>
                </tr>
                {data.schemaRun?.extractedFields
                    .sort((a, b) => {
                        return a.field.localeCompare(b.field);
                    })
                    .map((extractedField) => {
                        return (
                            <tr key={extractedField.uuid}>
                                <td>{extractedField.field}</td>
                                <td>{extractedField.value}</td>
                            </tr>
                        );
                    })}
            </table>
            <Box
                sx={{
                    m: 5,
                }}
            >
                {data.schemaRun?.type === SchemaRunType.ManualRun && (
                    <Button
                        disabled={createSchemaTestSnapshotLoading}
                        onClick={handleCreateSchemaTestSnapshot}
                        variant="contained"
                    >
                        Create test from run
                    </Button>
                )}
            </Box>
        </div>
    );
};
