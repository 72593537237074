/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from "lodash";

/**
 * Omit __typename from entity
 *
 * This is useful when receiving an entity from the backend then passing it
 * back into a mutation
 */
export const omitTypename = (entity: any): any => {
    if (isNil(entity)) {
        return entity;
    }
    const { __typename, ...rest } = entity;
    return rest;
};

export const omitUuid = (entity: any): any => {
    if (isNil(entity)) {
        return entity;
    }
    const { uuid, ...rest } = entity;
    return rest;
};
