import { exhaustive } from "shared/switch";
import {
    OrderSource as GraphqlOrderSource,
    OrderVolumeByContactReportQuery,
    ScannedOrderSource,
} from "../../../generated/graphql";

export enum StatisticsView {
    TABLE,
    CHART,
}

export enum OrderSource {
    MANUAL = "Manual",
    EMAIL_DOC_SCAN = "Email",
    UPLOADED_DOC_SCAN = "Doc scan",
    API = "API",
    EDI = "EDI",
    CSV_IMPORT = "CSV",
}

export const initialOrderVolumeData = {
    [OrderSource.MANUAL]: 0,
    [OrderSource.EMAIL_DOC_SCAN]: 0,
    [OrderSource.UPLOADED_DOC_SCAN]: 0,
    [OrderSource.API]: 0,
    [OrderSource.EDI]: 0,
    [OrderSource.CSV_IMPORT]: 0,
    total: 0,
};

export const getOrderSourceBucketByOrderSource = (
    orderSource: OrderSource,
    sourceAmounts: OrderVolumeByContactReportQuery["orderVolumeByContactReport"][0]["sourceAmounts"],
) => {
    switch (orderSource) {
        case OrderSource.MANUAL:
            return sourceAmounts.find(
                (data) => data.source === GraphqlOrderSource.ManuallyEntered,
            );
        case OrderSource.EMAIL_DOC_SCAN:
            return sourceAmounts.find(
                (data) =>
                    data.source === GraphqlOrderSource.DocumentScanning &&
                    data.scannedOrderSource ===
                        ScannedOrderSource.EmailIntegration,
            );
        case OrderSource.UPLOADED_DOC_SCAN:
            return sourceAmounts.find(
                (data) =>
                    data.source === GraphqlOrderSource.DocumentScanning &&
                    data.scannedOrderSource ===
                        ScannedOrderSource.UploadedDocument,
            );
        case OrderSource.API:
            return sourceAmounts.find(
                (data) => data.source === GraphqlOrderSource.Api,
            );
        case OrderSource.EDI:
            return sourceAmounts.find(
                (data) => data.source === GraphqlOrderSource.Edi,
            );
        case OrderSource.CSV_IMPORT:
            return sourceAmounts.find(
                (data) => data.source === GraphqlOrderSource.CsvImport,
            );
        default:
            return exhaustive(orderSource);
    }
};
