import { isNil } from "lodash";

export const filterNotNil = <T>(array: (T | undefined | null)[]): T[] => {
    return array.flatMap((v) => (isNil(v) ? [] : [v]));
};

/**
 * Break an array into {size} chunks
 * @param array
 * @param size
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size);
        result.push(chunk);
    }
    return result;
}
