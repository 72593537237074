import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import NumbersIcon from "@mui/icons-material/Numbers";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PercentIcon from "@mui/icons-material/Percent";
import {
    Divider,
    IconButton,
    Stack,
    TablePagination,
    Tooltip,
    Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { isNil, sumBy } from "lodash";
import { Chart } from "primereact/chart";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    ReportAggregationPeriod,
    useOrderVolumeByDateRangeReportQuery,
} from "../../../generated/graphql";
import TabPanel from "../../common/tab-panel/tab-panel";
import DateRangePicker, { DateOption } from "../../date-range-picker";
import TimeRangePeriodPicker from "../../time-range-period-picker";
import {
    getOrderSourceBucketByOrderSource,
    initialOrderVolumeData,
    OrderSource,
    StatisticsView,
} from "./utils";

type OrderVolumeDateRangeData = {
    startDate: Date;
    endDate: Date;
    [OrderSource.MANUAL]: number;
    [OrderSource.EMAIL_DOC_SCAN]: number;
    [OrderSource.UPLOADED_DOC_SCAN]: number;
    [OrderSource.API]: number;
    [OrderSource.EDI]: number;
    [OrderSource.CSV_IMPORT]: number;
    total: number;
};

const defaultChartRowsPerPage = 8;
const expandedChartRowsPerPage = 90;

const OrderVolumeByDateRangeStatistics = ({
    tab,
    companyUuid,
}: {
    tab: StatisticsView;
    companyUuid: string;
}) => {
    const tableRef = useRef(null);
    const [period, setPeriod] = useState<ReportAggregationPeriod>(
        ReportAggregationPeriod.Week,
    );
    const [dateOption, setDateOption] = useState<DateOption>({
        startDate: dayjs(new Date()).subtract(30, "days").toDate(),
        endDate: null,
    });
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [showPercentage, setShowPercentage] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const { data: orderVolumeByDateRangeData, loading } =
        useOrderVolumeByDateRangeReportQuery({
            variables: {
                orderVolumeByDateRangeInput: {
                    companyUuid,
                    startDate: dateOption.startDate,
                    endDate: dateOption.endDate,
                    period,
                },
            },
            fetchPolicy: "cache-first",
        });

    const chartRowsPerPage = useMemo(() => {
        return isExpanded ? expandedChartRowsPerPage : defaultChartRowsPerPage;
    }, [isExpanded]);

    const orderVolumeData = useMemo(() => {
        return orderVolumeByDateRangeData?.orderVolumeByDateRangeReport.map(
            (dateRangeData) => {
                const data: OrderVolumeDateRangeData = {
                    ...initialOrderVolumeData,
                    ...dateRangeData,
                };
                data.total = sumBy(
                    Object.values(OrderSource),
                    (source) =>
                        getOrderSourceBucketByOrderSource(
                            source,
                            dateRangeData.sourceAmounts,
                        )?.totalCount ?? 0,
                );
                Object.values(OrderSource).forEach((source) => {
                    data[source] =
                        getOrderSourceBucketByOrderSource(
                            source,
                            dateRangeData.sourceAmounts,
                        )?.totalCount ?? 0;
                    if (showPercentage) {
                        const roundedPercentage = Math.round(
                            (data[source] / data.total) * 100,
                        );
                        data[source] = Number.isNaN(roundedPercentage)
                            ? 0
                            : roundedPercentage;
                    }
                });
                return data;
            },
        );
    }, [orderVolumeByDateRangeData, showPercentage]);

    const chartData = useMemo(() => {
        if (isNil(orderVolumeData)) return {};
        const filteredOrderVolumeData = orderVolumeData.slice(
            page * chartRowsPerPage,
            (page + 1) * chartRowsPerPage,
        );
        return {
            labels: filteredOrderVolumeData.map(
                (d) =>
                    `${dayjs(d.startDate).format("MM/DD")}-${dayjs(d.endDate).format("MM/DD/YY")}`,
            ),
            datasets: Object.values(OrderSource).map((source) => ({
                label: source,
                data: filteredOrderVolumeData.map((d) => d[source]),
                hidden: source !== OrderSource.MANUAL,
            })),
        };
    }, [orderVolumeData, page, chartRowsPerPage]);

    const sourceTemplate = (
        data: OrderVolumeDateRangeData,
        options: ColumnBodyOptions,
    ) => {
        return (
            <span>
                {data[options.field as OrderSource] ?? ""}
                {showPercentage ? "%" : ""}
            </span>
        );
    };

    const dateRangeTemplate = (data: OrderVolumeDateRangeData) => {
        return (
            <span>
                {dayjs(data.startDate).format("MM/DD")}-
                {dayjs(data.endDate).format("MM/DD/YY")}
            </span>
        );
    };

    useEffect(() => {
        const lastPage = Math.max(
            Math.round((orderVolumeData?.length ?? 0) / chartRowsPerPage) - 1,
            0,
        );
        setPage(lastPage);
    }, [orderVolumeData?.length, chartRowsPerPage]);

    const header = (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                border: 1,
                borderBottom: 0,
                borderColor: "#E9ECEF",
                backgroundColor: "#f8f9fa",
                p: 0.5,
            }}
        >
            <Stack>
                <Typography sx={{ fontWeight: "bold" }}>
                    Order volume
                </Typography>
                <Typography variant="caption">(by period)</Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                {tab === StatisticsView.TABLE && (
                    <IconButton
                        size="small"
                        onClick={() => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            tableRef.current?.exportCSV();
                        }}
                    >
                        <FileDownloadIcon />
                    </IconButton>
                )}
                {tab === StatisticsView.CHART && (
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={orderVolumeData?.length ?? 0}
                        page={page}
                        onPageChange={(e, newPage) => {
                            setPage(newPage);
                        }}
                        backIconButtonProps={{
                            style: {
                                padding: 4,
                            },
                        }}
                        nextIconButtonProps={{
                            style: {
                                padding: 4,
                            },
                        }}
                        rowsPerPage={chartRowsPerPage}
                    />
                )}
                <TimeRangePeriodPicker period={period} onChange={setPeriod} />
                <Divider orientation="vertical" flexItem />
                <DateRangePicker
                    dateOption={dateOption}
                    onChange={(newDateOption) => setDateOption(newDateOption)}
                    excludeAll
                />
                <Divider orientation="vertical" flexItem />
                <Typography sx={{ fontWeight: "bold" }}>Metric</Typography>
                <Tooltip title="Counts">
                    <IconButton
                        disabled={showPercentage === false}
                        size="small"
                        onClick={() => {
                            setShowPercentage(false);
                        }}
                    >
                        <NumbersIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Percentage of total">
                    <IconButton
                        disabled={showPercentage}
                        size="small"
                        onClick={() => {
                            setShowPercentage(true);
                        }}
                    >
                        <PercentIcon />
                    </IconButton>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
                <IconButton
                    size="small"
                    onClick={() => {
                        setIsExpanded((prev) => !prev);
                    }}
                >
                    {isExpanded ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                </IconButton>
            </Stack>
        </Stack>
    );

    return (
        <Stack sx={{ overflowY: "scroll" }}>
            <div
                style={
                    isExpanded
                        ? {
                              position: "absolute",
                              zIndex: 10000,
                              top: 0,
                              left: 0,
                              width: "100vw",
                              height: "100vh",
                              backgroundColor: "white",
                          }
                        : {}
                }
            >
                {header}
                <TabPanel panelValue={StatisticsView.CHART} selectedValue={tab}>
                    <Chart
                        height={isExpanded ? "90vh" : "320px"}
                        type="bar"
                        data={chartData}
                        options={{
                            indexAxis: "x",
                            maintainAspectRatio: false,
                            aspectRatio: 0.8,
                            scales: {
                                x: {
                                    ticks: {
                                        font: {
                                            weight: 500,
                                        },
                                        precision: 0,
                                    },
                                    grid: {
                                        display: false,
                                        drawBorder: false,
                                    },
                                },
                                y: {
                                    grid: {
                                        drawBorder: false,
                                    },
                                },
                            },
                        }}
                    />
                </TabPanel>
                <TabPanel panelValue={StatisticsView.TABLE} selectedValue={tab}>
                    <DataTable
                        ref={tableRef}
                        key={`${companyUuid}-open-${isExpanded}`}
                        value={orderVolumeData}
                        sortMode="multiple"
                        removableSort
                        showGridlines
                        rows={10}
                        loading={loading}
                        dataKey="id"
                        emptyMessage="No data"
                        size="small"
                        scrollable
                        scrollHeight={isExpanded ? "100vh" : "325px"}
                        stripedRows
                        virtualScrollerOptions={{ itemSize: 25 }}
                    >
                        <Column
                            field="startDate"
                            header="Start Date"
                            sortable
                            filter
                            filterPlaceholder="Search by contact name"
                            body={dateRangeTemplate}
                        />
                        {Object.values(OrderSource).map((source) => (
                            <Column
                                key={source}
                                header={source}
                                field={source}
                                body={sourceTemplate}
                                sortable
                            />
                        ))}
                        <Column header="Total" field="total" sortable />
                    </DataTable>
                </TabPanel>
            </div>
        </Stack>
    );
};
export default OrderVolumeByDateRangeStatistics;
