import { Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { FieldError } from "react-hook-form";
import Select from "react-select";
import { useCompaniesQuery } from "../../generated/graphql";
import { SelectOption } from "./schema-testing/types";

export const GLOBAL_SCHEMA_NO_COMPANY = "GLOBAL_SCHEMA_NO_COMPANY";

const CompanySelect = ({
    currentCompanyUuid,
    onChange,
    error,
    isText = false,
    allowNoCompany = false,
    noLabel = false,
    minWidth,
    maxWidth,
}: {
    currentCompanyUuid: string | undefined | null;
    onChange: (option: SelectOption | undefined | null) => void;
    error?: FieldError | undefined;
    isText?: boolean;
    allowNoCompany?: boolean;
    noLabel?: boolean;
    minWidth?: number;
    maxWidth?: number;
}) => {
    const { data: companiesData } = useCompaniesQuery({
        fetchPolicy: "cache-first",
    });

    const companyOptions = useMemo(() => {
        const opts = companiesData?.companies.map((company) => {
            return {
                value: company.uuid,
                label: company.name,
            };
        });
        if (allowNoCompany) {
            opts?.unshift({
                value: GLOBAL_SCHEMA_NO_COMPANY,
                label: "All",
            });
        }
        return opts;
    }, [allowNoCompany, companiesData?.companies]);

    const currentOption = companyOptions?.find(
        (option) =>
            option.value === currentCompanyUuid ||
            (allowNoCompany &&
                option.value === GLOBAL_SCHEMA_NO_COMPANY &&
                isNil(currentCompanyUuid)),
    );

    if (isText) {
        return <Typography>{currentOption?.label}</Typography>;
    }

    return (
        <div style={{ minWidth, maxWidth, fontWeight: "normal" }}>
            {!noLabel && <span>Select company</span>}

            <Select
                menuPosition="fixed"
                options={companyOptions}
                onChange={(option) => {
                    onChange(option);
                }}
                placeholder="Select company"
                value={currentOption}
            />
            {!isNil(error) && (
                <Typography sx={{ color: "red", fontSize: 12 }}>
                    Company is required
                </Typography>
            )}
        </div>
    );
};

export default React.memo(CompanySelect);
