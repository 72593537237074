import {
    Alert,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    Snackbar,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Controller, SubmitHandler } from "react-hook-form";
import ReactJson from "react-json-view";
import {
    useMeQuery,
    useValidateCrownChargesFromPdfMutation,
    useValidateCrownChargesFromCsvMutation,
    ValidateCrownChargesOutput,
} from "../../generated/graphql";
import TabPanel from "../common/tab-panel/tab-panel";
import OrderImportConfigurationEditor from "../orders-importer/order-import-configuration-editor";
import useCrownChargesValidatorForm, {
    CrownChargesValidatorFormValues,
} from "./use-crown-charges-validator-form";

const EMPTY_OUTPUT: ValidateCrownChargesOutput = {
    correctCrownIDs: [],
    wrongCrownIDs: [],
    resultsUrl: "",
    csvResults: [[]],
};

enum ValidatorTab {
    CSV,
    PDF,
}

const CrownChargesValidator = () => {
    const [validateFromCSV, { loading: validateFromCSVLoading }] =
        useValidateCrownChargesFromCsvMutation();
    const [validateFromPDF, { loading: validateFromPDFLoading }] =
        useValidateCrownChargesFromPdfMutation();
    const { data: meData } = useMeQuery({
        fetchPolicy: "cache-first",
    });
    const company = meData?.me?.company;

    const [tab, setTab] = useState<ValidatorTab>(ValidatorTab.CSV);

    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useCrownChargesValidatorForm();

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const DEFAULT_MESSAGE = "Error validating charges";
    const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);
    const [results, setResults] =
        useState<ValidateCrownChargesOutput>(EMPTY_OUTPUT);
    const [displayFailedOnly, setDisplayFailedOnly] = useState(false);
    const [searchCID, setSearchCID] = useState("");

    useEffect(() => {
        reset({
            ordersUrl: "",
            companyUuid: "",
        });
    }, [reset]);

    const onSubmit: SubmitHandler<CrownChargesValidatorFormValues> = async (
        data,
    ) => {
        const { ordersUrl, invoicesUrl } = data;

        try {
            if (isNil(company)) {
                setErrorMessage("No company found");
                setErrorVisible(true);
                return;
            }
            switch (tab) {
                case ValidatorTab.PDF: {
                    if (isNil(invoicesUrl) || isEmpty(invoicesUrl)) {
                        setErrorMessage("Invoice URL is required");
                        setErrorVisible(true);
                        break;
                    }
                    const response = await validateFromPDF({
                        variables: {
                            validateCrownChargesInput: {
                                ordersUrl,
                                invoicesUrl,
                                companyUuid: company.uuid,
                            },
                        },
                    });
                    const { errors: resErrors } = response;
                    if (!isEmpty(resErrors)) {
                        setErrorMessage(
                            resErrors?.map((err) => err.message).join(", ") ??
                                DEFAULT_MESSAGE,
                        );
                        setErrorVisible(true);
                    } else {
                        setResults(
                            response.data?.validateCrownChargesFromPDF ??
                                EMPTY_OUTPUT,
                        );
                        setSuccessVisible(true);
                    }
                    break;
                }
                case ValidatorTab.CSV:
                default: {
                    const response = await validateFromCSV({
                        variables: {
                            validateCrownChargesInput: {
                                ordersUrl,
                                companyUuid: company.uuid,
                            },
                        },
                    });
                    const { errors: resErrors } = response;
                    if (!isEmpty(resErrors)) {
                        setErrorMessage(
                            resErrors?.map((err) => err.message).join(", ") ??
                                DEFAULT_MESSAGE,
                        );
                        setErrorVisible(true);
                    } else {
                        setResults(
                            response.data?.validateCrownChargesFromCSV ??
                                EMPTY_OUTPUT,
                        );
                        setSuccessVisible(true);
                    }
                    break;
                }
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
            /* eslint-disable-next-line no-console */
            console.error(e);
        }
    };

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully ran validator
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">Crown Charges Validator</Typography>
                <Typography variant="h5">Company: {company?.name}</Typography>
                <Typography variant="subtitle1">
                    {`For accessorial custom charges, please ensure the rateCode
                    matches the accessorial's code or name in Pallet.`}
                </Typography>
                <Typography variant="subtitle1">
                    Supported charge types:{" "}
                    {tab === ValidatorTab.CSV
                        ? "DEL, LH, PU, XFR, SUR"
                        : "DELIVERY CHARGE, PICKUP CHARGE, LH: ABC-XYZ, surcharges"}
                    .
                </Typography>
                <Typography variant="subtitle1">
                    <a href="https://www.loom.com/share/2a1004f0b19c42f8a85c75dbce0670ea">
                        Demo
                    </a>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
                    <Tab label="SQL server data" value={ValidatorTab.CSV} />
                    <Tab label="Crown data only" value={ValidatorTab.PDF} />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <TabPanel
                    key={ValidatorTab.CSV}
                    panelValue={ValidatorTab.CSV}
                    selectedValue={tab}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Controller
                                name="ordersUrl"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <InputLabel>
                                            Orders URL (
                                            <a href="https://docs.google.com/spreadsheets/d/1tXLSOV3i-mctBN07snNQ49Nv_Pfne8BTX4NKHen_zKk/edit?usp=sharing">
                                                Sample CSV
                                            </a>
                                            )
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            error={!isNil(errors.ordersUrl)}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {!isNil(errors.ordersUrl) && (
                                            <FormHelperText
                                                sx={{ color: "#D32F2F" }}
                                            >
                                                {errors.ordersUrl.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel
                    key={ValidatorTab.PDF}
                    panelValue={ValidatorTab.PDF}
                    selectedValue={tab}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Controller
                                name="ordersUrl"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <InputLabel>
                                            Orders URL (
                                            <a href="https://docs.google.com/spreadsheets/d/11IYPMolGrY0QZYFbdvgCMmKMOlKpo3bX/edit?usp=sharing&ouid=103638095178890797777&rtpof=true&sd=true">
                                                Sample CSV from Customer Daily
                                                Log
                                            </a>
                                            )
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            error={!isNil(errors.ordersUrl)}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {!isNil(errors.ordersUrl) && (
                                            <FormHelperText
                                                sx={{ color: "#D32F2F" }}
                                            >
                                                {errors.ordersUrl.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="invoicesUrl"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <InputLabel>
                                            Invoices URL (
                                            <a href="https://misc-hosting-cashew.s3.us-west-1.amazonaws.com/ssf_invoice.pdf">
                                                Sample PDF
                                            </a>
                                            )
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            error={!isNil(errors.invoicesUrl)}
                                            value={value}
                                            onChange={onChange}
                                        />
                                        {!isNil(errors.invoicesUrl) && (
                                            <FormHelperText
                                                sx={{ color: "#D32F2F" }}
                                            >
                                                {errors.invoicesUrl.message}
                                            </FormHelperText>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={validateFromCSVLoading || validateFromPDFLoading}
                    variant="contained"
                >
                    Validate Charges
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Divider />
                <Typography variant="h5">Results</Typography>
                <Typography variant="subtitle1">
                    {results.correctCrownIDs.length} passed,{" "}
                    {results.wrongCrownIDs.length} failed
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ReactJson src={results} collapsed />
            </Grid>
            <Grid item xs={12}>
                <CSVLink
                    data={results.csvResults}
                    filename="crown-charge-validation-results.csv"
                >
                    <Button variant="contained">Download as CSV</Button>
                </CSVLink>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">
                    Note: Only 100 rows are rendered at a time -- download CSV
                    to see all results.
                </Typography>
                <TextField
                    label="Search by Crown ID"
                    value={searchCID}
                    onChange={(e) => setSearchCID(e.target.value)}
                    size="small"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={displayFailedOnly}
                            onChange={(e) => {
                                setDisplayFailedOnly(e.target.checked);
                            }}
                        />
                    }
                    label="Only show failed"
                    sx={{ mb: "20px", ml: "20px" }}
                />
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {results.csvResults
                                    .at(0)
                                    ?.map((header: string) => (
                                        <TableCell
                                            key={header}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            {header}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.csvResults
                                .slice(1)
                                .filter(
                                    (row) =>
                                        (!displayFailedOnly ||
                                            row.at(3)?.toUpperCase() ===
                                                "FALSE") &&
                                        (searchCID.length === 0 ||
                                            row.at(0)?.includes(searchCID)),
                                )
                                .slice(0, 100)
                                .map((row: string[], idx: number) => (
                                    <TableRow
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={idx}
                                        sx={{
                                            backgroundColor:
                                                row.at(3)?.toUpperCase() ===
                                                "FALSE"
                                                    ? "#FAA0A0"
                                                    : undefined,
                                        }}
                                    >
                                        {row.map((cell: string) => (
                                            <TableCell key={cell}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <OrderImportConfigurationEditor />
        </Grid>
    );
};

export default CrownChargesValidator;
