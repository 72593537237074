import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { isEmpty, isNil } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { SearchSchemasQuery } from "../../../generated/graphql";
import useSchemaStore from "../schema-testing/schema-store";

const ConfirmBulkMatchDialog = ({
    open,
    setOpen,
    selectedSchemas,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedSchemas: SearchSchemasQuery["searchSchemas"];
}) => {
    const matchSchemaDocument = useSchemaStore(
        (state) => state.matchSchemaDocument,
    );
    const schemasToMatch = selectedSchemas.filter(
        (s) => s.isActive === true && !isNil(s.firstTestSchemaDocument),
    );

    const schemasUnableToMatch = selectedSchemas.filter(
        (s) => s.isActive === false || isNil(s.firstTestSchemaDocument),
    );

    const onConfirm = () => {
        schemasToMatch.forEach((s) => {
            if (!isNil(s.firstTestSchemaDocument)) {
                matchSchemaDocument(s, s.firstTestSchemaDocument);
            }
        });
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle sx={{ fontWeight: "bold" }}>
                Bulk match schemas
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {!isEmpty(schemasToMatch) && (
                        <Stack>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {schemasToMatch.length} schema(s) will be run:
                            </Typography>
                            <Box
                                sx={{ maxHeight: "200px", overflowY: "scroll" }}
                            >
                                <Table size="small">
                                    <TableBody>
                                        {schemasToMatch.map((s) => (
                                            <TableRow key={s.uuid}>
                                                <TableCell>{s.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Stack>
                    )}
                    {!isEmpty(schemasUnableToMatch) && (
                        <Stack>
                            <Typography sx={{ fontWeight: "bold" }}>
                                {schemasUnableToMatch.length} schema(s) cannot
                                be run:
                            </Typography>
                            <Box
                                sx={{ maxHeight: "200px", overflowY: "scroll" }}
                            >
                                <Table size="small">
                                    <TableBody>
                                        {schemasUnableToMatch.map((s) => (
                                            <TableRow key={s.uuid}>
                                                <TableCell>{s.name}</TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="error"
                                                        variant="caption"
                                                    >
                                                        {/* eslint-disable-next-line no-nested-ternary */}
                                                        {s.isActive !== true
                                                            ? "Inactive"
                                                            : isNil(
                                                                    s.firstTestSchemaDocument,
                                                                )
                                                              ? "No test documents"
                                                              : ""}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button variant="contained" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmBulkMatchDialog;
