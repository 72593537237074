import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isEmpty, isNil } from "lodash";
import React, { useState } from "react";
import {
    OutlookIntegrationsDocument,
    useCreateOutlookIntegrationMutation,
} from "../../../generated/graphql";
import CompanySelect from "../company-select";
import TerminalSelect from "../terminal-select";

const CreateOutlookIntegrationDialog = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const [companyUuid, setCompanyUuid] = useState<string>();
    const [entraUserId, setEntraUserId] = useState<string>();
    const [entraTenantId, setEntraTenantId] = useState<string>();
    const [terminalUuid, setTerminalUuid] = useState<string>();
    const [emailStartDate, setEmailStartDate] = useState<Date>();
    const [createOutlookIntegration] = useCreateOutlookIntegrationMutation();
    const [errMessage, setErrMessage] = useState<string | undefined>();

    const onSave = async () => {
        if (isNil(companyUuid)) {
            setErrMessage("Please select a company");
            return;
        }
        if (isNil(entraUserId) || isEmpty(entraUserId)) {
            setErrMessage("Please enter an email");
            return;
        }
        if (isNil(entraTenantId) || isEmpty(entraTenantId)) {
            setErrMessage(
                "Please enter a tenant ID (log into entra.microsoft.com)",
            );
            return;
        }
        const res = await createOutlookIntegration({
            variables: {
                outlookIntegrationCreateInput: {
                    companyUuid,
                    entraUserId,
                    entraTenantId,
                    terminalUuid,
                    emailCreatedAtStartDate: emailStartDate,
                    outboundEmailDomains: [],
                },
            },
            refetchQueries: [OutlookIntegrationsDocument],
        });
        if (!isNil(res.errors)) {
            setErrMessage(res.errors[0]?.message);
        } else {
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold" }}>
                Create outlook integration
            </DialogTitle>
            <Stack sx={{ px: 3 }} spacing={2}>
                <CompanySelect
                    currentCompanyUuid={companyUuid}
                    onChange={(option) => {
                        setCompanyUuid(option?.value);
                    }}
                />
                <TextField
                    label="Email"
                    sx={{ width: "100%" }}
                    size="small"
                    value={entraUserId}
                    onChange={(e) => {
                        setEntraUserId(e.target.value);
                    }}
                />
                <TextField
                    label="Tenant ID"
                    sx={{ width: "100%" }}
                    size="small"
                    value={entraTenantId}
                    onChange={(e) => {
                        setEntraTenantId(e.target.value);
                    }}
                />
                <TerminalSelect
                    companyUuid={companyUuid}
                    currentTerminalUuid={terminalUuid}
                    setTerminalUuid={(uuid) => {
                        setTerminalUuid(uuid ?? undefined);
                    }}
                    showLabel
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        formatDensity="dense"
                        label="Start ingesting from"
                        value={emailStartDate}
                        onChange={(value: Date | null) => {
                            if (!isNil(value)) {
                                /* eslint-disable-next-line no-console */
                                console.log(value.toISOString());
                                setEmailStartDate(value);
                            }
                        }}
                    />
                </LocalizationProvider>
                {!isNil(errMessage) && (
                    <Typography variant="caption" color="error">
                        {errMessage}
                    </Typography>
                )}
            </Stack>
            <DialogActions>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOutlookIntegrationDialog;
