/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { isNil, sumBy } from "lodash";
import { v4 } from "uuid";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import apolloClient from "../../../apollo-client";
import {
    VerifySchemaDocumentMatchesForCompanyDocument,
    VerifySchemaDocumentMatchesForCompanyQuery,
    VerifySchemaDocumentMatchesForCompanyQueryVariables,
} from "../../../generated/graphql";
import {
    MatchSchemaDocumentJob,
    MatchStatus,
    ScanSchemaDocumentJob,
    Schema,
    SchemaDocument,
} from "./types";

type SchemaStore = {
    hoveredScannedOrderUuid: string | undefined;
    isViewingScanResult: boolean;
    selectedSchemaDocuments: {
        schemaDocument: SchemaDocument;
        schema: Schema;
    }[];
    matchSchemaDocumentJobs: MatchSchemaDocumentJob[];
    scanSchemaDocumentJobs: ScanSchemaDocumentJob[];
};

type SchemaActions = {
    setHoveredScannedOrderUuid: (uuid: string | undefined) => void;
    setIsViewingScanResult: (isViewing: boolean) => void;
    addScanSchemaDocumentJob: ({
        schema,
        schemaDocument,
        scannedOrderUuid,
    }: {
        schema: Schema | undefined;
        schemaDocument: SchemaDocument | undefined;
        scannedOrderUuid: string;
    }) => void;
    deleteScanSchemaDocumentJob: (id: string) => void;
    clearAllScanSchemaDocumentJobs: () => void;
    selectSchemaDocuments: (
        schema: Schema,
        schemaDocuments: SchemaDocument[],
    ) => void;
    deselectSchemaDocument: (uuid: string) => void;
    deselectAllSchemaDocuments: () => void;
    updateSchemaDocumentJob: ({
        id,
        matchStatus,
        resultJson,
        error,
    }: {
        id: string;
        matchStatus: MatchStatus;
        resultJson?: any;
        error?: string;
    }) => void;
    deleteMatchSchemaDocumentJob: (id: string) => void;
    clearAllMatchSchemaDocumentJobs: () => void;
    matchSchemaDocument: (
        schema: Schema,
        schemaDocument: SchemaDocument,
    ) => void;
    reset: () => void;
};

const initialState: SchemaStore = {
    hoveredScannedOrderUuid: undefined,
    isViewingScanResult: false,
    selectedSchemaDocuments: [],
    matchSchemaDocumentJobs: [],
    scanSchemaDocumentJobs: [],
};

const useSchemaStore = create(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    immer<SchemaStore & SchemaActions>((set, get) => ({
        ...initialState,
        setHoveredScannedOrderUuid: (uuid: string | undefined) =>
            set((state) => {
                state.hoveredScannedOrderUuid = uuid;
            }),
        setIsViewingScanResult: (isViewing: boolean) =>
            set((state) => {
                state.isViewingScanResult = isViewing;
            }),
        addScanSchemaDocumentJob: ({
            schema,
            schemaDocument,
            scannedOrderUuid,
        }: {
            schema: Schema | undefined;
            schemaDocument: SchemaDocument | undefined;
            scannedOrderUuid: string;
        }) =>
            set((state) => {
                state.scanSchemaDocumentJobs.push({
                    id: v4(),
                    schema,
                    schemaDocument,
                    resultScannedOrderUuid: scannedOrderUuid,
                });
            }),
        deleteScanSchemaDocumentJob: (id: string) =>
            set((state) => {
                state.scanSchemaDocumentJobs =
                    state.scanSchemaDocumentJobs.filter((s) => s.id !== id);
            }),
        clearAllScanSchemaDocumentJobs: () =>
            set((state) => {
                state.scanSchemaDocumentJobs = [];
            }),
        selectSchemaDocuments: (
            schema: Schema,
            schemaDocuments: SchemaDocument[],
        ) =>
            set((state) => {
                const newSelectedSchemaDocuments =
                    state.selectedSchemaDocuments.filter(
                        (s) =>
                            !schemaDocuments.some(
                                (sd) => sd.uuid === s.schemaDocument.uuid,
                            ),
                    );
                schemaDocuments.forEach((schemaDocument) => {
                    newSelectedSchemaDocuments.push({
                        schema,
                        schemaDocument,
                    });
                });
                state.selectedSchemaDocuments = newSelectedSchemaDocuments;
            }),
        deselectSchemaDocument: (uuid: string) =>
            set((state) => {
                state.selectedSchemaDocuments =
                    state.selectedSchemaDocuments.filter(
                        (s) => s.schemaDocument.uuid !== uuid,
                    );
            }),
        deselectAllSchemaDocuments: () => set({ selectedSchemaDocuments: [] }),
        updateSchemaDocumentJob: async ({
            id,
            matchStatus,
            resultJson,
            error,
        }: {
            id: string;
            matchStatus: MatchStatus;
            resultJson?: any;
            error?: string;
        }) => {
            set((state) => {
                const matchSchemaDocumentJob =
                    state.matchSchemaDocumentJobs.find((job) => job.id === id);
                if (!isNil(matchSchemaDocumentJob)) {
                    matchSchemaDocumentJob.finishedAt = new Date();
                    matchSchemaDocumentJob.matchStatus = matchStatus;
                    matchSchemaDocumentJob.resultJson = resultJson;
                    matchSchemaDocumentJob.error = error;
                }
            });
        },
        deleteMatchSchemaDocumentJob: (id: string) =>
            set((state) => {
                state.matchSchemaDocumentJobs =
                    state.matchSchemaDocumentJobs.filter(
                        (job) => job.id !== id,
                    );
            }),
        clearAllMatchSchemaDocumentJobs: () =>
            set((state) => {
                state.matchSchemaDocumentJobs = [];
            }),
        matchSchemaDocument: async (
            schema: Schema,
            schemaDocument: SchemaDocument,
        ) => {
            if (isNil(schemaDocument.company)) {
                return;
            }

            const id = v4();
            set((state) => {
                state.matchSchemaDocumentJobs.push({
                    id,
                    schema,
                    startedAt: new Date(),
                    schemaDocument,
                    matchStatus: MatchStatus.IN_PROGRESS,
                    finishedAt: null,
                });
            });

            const res = await apolloClient.query<
                VerifySchemaDocumentMatchesForCompanyQuery,
                VerifySchemaDocumentMatchesForCompanyQueryVariables
            >({
                query: VerifySchemaDocumentMatchesForCompanyDocument,
                variables: {
                    companyUuid: schemaDocument.company.uuid,
                    schemaDocumentUuid: schemaDocument.uuid,
                },
            });

            if (!isNil(res.error) || isNil(res.data)) {
                get().updateSchemaDocumentJob({
                    id,
                    matchStatus: MatchStatus.FAILED,
                    error: `API failure: ${res.error?.message}`,
                });
                return;
            }

            const payload = JSON.parse(
                res.data.verifySchemaDocumentMatchesForCompany,
            );
            const documentPageResults = payload.documentResults[0];

            if (
                documentPageResults.every((r: any) => r.type === "SUCCESS") ===
                true
            ) {
                const matchDetails = documentPageResults[0]?.matchDetails;
                if (matchDetails?.matchedSchemaUuid !== schema.uuid) {
                    get().updateSchemaDocumentJob({
                        id,
                        matchStatus: MatchStatus.FAILED,
                        error: `Document matched to wrong schema - ${matchDetails?.matchedSchemaName}`,
                        resultJson: payload,
                    });
                    return;
                }
                get().updateSchemaDocumentJob({
                    id,
                    matchStatus: MatchStatus.SUCCESS,
                    resultJson: payload,
                });
            } else {
                const pageFailures = sumBy(documentPageResults, (r: any) =>
                    r.type === "FAILURE" ? 1 : 0,
                );
                const failed = documentPageResults.find(
                    (r: any) => r.type === "FAILURE",
                );
                let fractionFailureText = "";
                if (pageFailures === documentPageResults.length) {
                    fractionFailureText = `${pageFailures} / ${documentPageResults.length} failures: `;
                }
                get().updateSchemaDocumentJob({
                    id,
                    matchStatus: MatchStatus.FAILED,
                    error: `${fractionFailureText}${failed.matchDetails.specificFailureReason}`,
                    resultJson: payload,
                });
            }
        },
        reset: () => set(initialState),
    })),
);

export default useSchemaStore;
