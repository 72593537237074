import {
    Alert,
    Box,
    Button,
    Chip,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { sentenceCase } from "change-case";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import {
    SpecialAccessorialType,
    useImportCrownAccessorialMutation,
    useMeQuery,
    useShallowContactsQuery,
    useTariffZoneGroupsQuery,
    useTerminalsQuery,
} from "../../generated/graphql";
import { NO_ZONE_GROUP } from "../tariff-importer/use-import-tariff-form";
import { AccessorialImportType } from "./types";
import useImportAccessorialForm, {
    ImportAccessorialFormValues,
} from "./use-import-accessorial-form";

const ALL_TERMINALS = "All Terminals";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AccessorialImporter = () => {
    const [importCrownAccessorial, { loading: importCrownAccessorialLoading }] =
        useImportCrownAccessorialMutation();
    const { data: meData } = useMeQuery({
        fetchPolicy: "cache-first",
    });
    const company = meData?.me?.company;
    const { data: contactsData } = useShallowContactsQuery();
    const { data: tariffZoneGroupsData } = useTariffZoneGroupsQuery({
        variables: {
            findTariffZoneGroupsInput: {
                isArchived: false,
            },
        },
    });
    const { data: terminalsData } = useTerminalsQuery({
        fetchPolicy: "cache-first",
    });
    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useImportAccessorialForm();
    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const accessorialImportType = watch("accessorialImportType");
    const specialAccessorialType = watch("specialAccessorialType");

    useEffect(() => {
        reset({
            accessorialUrl: "",
            accessorialImportType: AccessorialImportType.ORIGINAL,
            contactUuids: [],
            tariffZoneGroupId: NO_ZONE_GROUP,
        });
    }, [reset]);

    const onSubmit: SubmitHandler<ImportAccessorialFormValues> = async (
        data,
    ) => {
        const {
            accessorialUrl,
            name,
            code,
            accessorialImportType: newAccessorialImportType,
            fuelSurchargePercentageRate,
            contactUuids,
            terminalUuid,
            percentForSettlement,
            tariffZoneGroupId,
            specialAccessorialType: newSpecialAccessorialType,
        } = data;
        if (
            newAccessorialImportType !== AccessorialImportType.ORIGINAL &&
            isNil(name)
        ) {
            return;
        }
        try {
            const response = await importCrownAccessorial({
                variables: {
                    importCrownAccessorialInput: {
                        accessorialUrl,
                        name: name ?? "",
                        code,
                        contactUuids,
                        useContactTariffZones: false,
                        terminalUuid,
                        fuelSurchargePercentageRate: !isNil(
                            fuelSurchargePercentageRate,
                        )
                            ? parseFloat(fuelSurchargePercentageRate)
                            : null,
                        percentForSettlement: !isNil(percentForSettlement)
                            ? parseFloat(percentForSettlement)
                            : null,
                        isSpecial:
                            accessorialImportType ===
                            AccessorialImportType.SPECIAL,
                        isZoneBased:
                            accessorialImportType ===
                            AccessorialImportType.ZONE_BASED,
                        specialAccessorialType:
                            accessorialImportType ===
                            AccessorialImportType.SPECIAL
                                ? newSpecialAccessorialType
                                : null,
                        tariffZoneGroupId:
                            tariffZoneGroupId === NO_ZONE_GROUP ||
                            (accessorialImportType ===
                                AccessorialImportType.SPECIAL &&
                                newSpecialAccessorialType ===
                                    SpecialAccessorialType.MileageBased)
                                ? null
                                : tariffZoneGroupId,
                    },
                },
            });
            if (response.data?.importCrownAccessorial.success === true) {
                setSuccessVisible(true);
                setErrorMessage(undefined);
            } else {
                setErrorVisible(true);
                const error =
                    response.data?.importCrownAccessorial?.error ?? undefined;
                setErrorMessage(error);
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
        }
    };

    const getAccessorialImportTypeCopy = (
        type: AccessorialImportType,
    ): string => {
        if (type === AccessorialImportType.SPECIAL) {
            return "Special";
        }
        if (type === AccessorialImportType.ZONE_BASED) {
            return "Zone Based";
        }
        return "Original (Standard, Weight-Based, Wait-Time, Unit-Based)";
    };

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully imported accessorial
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">Crown Accessorial Importer</Typography>
                <Typography variant="h5">Company: {company?.name}</Typography>
                <Typography variant="subtitle1">
                    Sample import files:
                </Typography>
                <li>
                    <a
                        href="https://misc-hosting-cashew.s3.us-west-1.amazonaws.com/sample-imports/mileage_based_special.csv"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Mileage-based special (Baton Rouge Cargo)
                    </a>
                </li>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="accessorialUrl"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Accessorial URL</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                error={!isNil(errors.accessorialUrl)}
                                value={value}
                                onChange={onChange}
                            />
                            {!isNil(errors.accessorialUrl) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.accessorialUrl.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="accessorialImportType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Accessorial Type</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.accessorialImportType)}
                            >
                                <MenuItem
                                    key={AccessorialImportType.ORIGINAL}
                                    value={AccessorialImportType.ORIGINAL}
                                >
                                    {getAccessorialImportTypeCopy(
                                        AccessorialImportType.ORIGINAL,
                                    )}
                                </MenuItem>
                                <MenuItem
                                    key={AccessorialImportType.SPECIAL}
                                    value={AccessorialImportType.SPECIAL}
                                >
                                    {getAccessorialImportTypeCopy(
                                        AccessorialImportType.SPECIAL,
                                    )}
                                </MenuItem>
                                <MenuItem
                                    key={AccessorialImportType.ZONE_BASED}
                                    value={AccessorialImportType.ZONE_BASED}
                                >
                                    {getAccessorialImportTypeCopy(
                                        AccessorialImportType.ZONE_BASED,
                                    )}
                                </MenuItem>
                            </Select>
                            {!isNil(errors.accessorialImportType) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.accessorialImportType.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            {(accessorialImportType === AccessorialImportType.SPECIAL ||
                accessorialImportType === AccessorialImportType.ZONE_BASED) && (
                <>
                    <Grid item xs={4}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel>Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        error={!isNil(errors.name)}
                                        value={value}
                                        onChange={onChange}
                                    />
                                    {!isNil(errors.name) && (
                                        <FormHelperText
                                            sx={{ color: "#D32F2F" }}
                                        >
                                            {errors.name.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="code"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel>Accessorial Code</InputLabel>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        error={!isNil(errors.code)}
                                        value={value}
                                        onChange={onChange}
                                    />
                                    {!isNil(errors.code) && (
                                        <FormHelperText
                                            sx={{ color: "#D32F2F" }}
                                        >
                                            {errors.code.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="contactUuids"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel id="age">Contact</InputLabel>
                                    <Select
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        multiple
                                        sx={{ width: "100%" }}
                                        error={!isNil(errors.contactUuids)}
                                        renderValue={(selected) => (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: 0.5,
                                                }}
                                            >
                                                {selected?.map((v) => (
                                                    <Chip
                                                        key={v}
                                                        label={
                                                            contactsData?.contacts?.find(
                                                                (c) =>
                                                                    c.uuid ===
                                                                    v,
                                                            )?.displayName ??
                                                            "-"
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight:
                                                        ITEM_HEIGHT * 4.5 +
                                                        ITEM_PADDING_TOP,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {contactsData?.contacts?.map((c) => {
                                            return (
                                                <MenuItem
                                                    key={c.uuid}
                                                    value={c.uuid}
                                                    // style={getStyles(name, personName, theme)}
                                                >
                                                    {c.displayName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {!isNil(errors.contactUuids) && (
                                        <FormHelperText
                                            sx={{ color: "#D32F2F" }}
                                        >
                                            {errors.contactUuids.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="percentForSettlement"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel>
                                        Settlement Percentage Rate
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        size="small"
                                        error={
                                            !isNil(errors.percentForSettlement)
                                        }
                                        value={value}
                                        onChange={onChange}
                                    />
                                    {!isNil(errors.percentForSettlement) && (
                                        <FormHelperText
                                            sx={{ color: "#D32F2F" }}
                                        >
                                            {
                                                errors.percentForSettlement
                                                    .message
                                            }
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Controller
                            name="terminalUuid"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <>
                                    <InputLabel id="age">Terminal</InputLabel>
                                    <Select
                                        size="small"
                                        onChange={onChange}
                                        value={value}
                                        sx={{ width: "100%" }}
                                        error={!isNil(errors.terminalUuid)}
                                    >
                                        <MenuItem
                                            key={ALL_TERMINALS}
                                            value={ALL_TERMINALS}
                                        >
                                            {ALL_TERMINALS}
                                        </MenuItem>
                                        {terminalsData?.terminals?.map(
                                            (terminal) => {
                                                return (
                                                    <MenuItem
                                                        key={terminal.uuid}
                                                        value={terminal.uuid}
                                                    >
                                                        {`${terminal.name} (${terminal.code})`}
                                                    </MenuItem>
                                                );
                                            },
                                        )}
                                    </Select>
                                    {!isNil(errors.terminalUuid) && (
                                        <FormHelperText
                                            sx={{ color: "#D32F2F" }}
                                        >
                                            {errors.terminalUuid.message}
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </Grid>

                    {accessorialImportType ===
                        AccessorialImportType.SPECIAL && (
                        <>
                            <Grid item xs={4}>
                                <Controller
                                    name="specialAccessorialType"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <>
                                            <InputLabel id="age">
                                                Special Accessorial Type
                                            </InputLabel>
                                            <Select
                                                size="small"
                                                onChange={onChange}
                                                value={value}
                                                sx={{ width: "100%" }}
                                                error={
                                                    !isNil(
                                                        errors.specialAccessorialType,
                                                    )
                                                }
                                            >
                                                {[
                                                    SpecialAccessorialType.ZoneBased,
                                                    SpecialAccessorialType.MileageBased,
                                                ].map((specialType) => {
                                                    return (
                                                        <MenuItem
                                                            key={specialType}
                                                            value={specialType}
                                                        >
                                                            {sentenceCase(
                                                                specialType,
                                                            )}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {!isNil(
                                                errors.specialAccessorialType,
                                            ) && (
                                                <FormHelperText
                                                    sx={{
                                                        color: "#D32F2F",
                                                    }}
                                                >
                                                    {
                                                        errors
                                                            .specialAccessorialType
                                                            .message
                                                    }
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                            {specialAccessorialType ===
                                SpecialAccessorialType.ZoneBased && (
                                <Grid item xs={4}>
                                    <Controller
                                        name="tariffZoneGroupId"
                                        control={control}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <>
                                                <InputLabel id="age">
                                                    Zone Group
                                                </InputLabel>
                                                <Select
                                                    size="small"
                                                    onChange={onChange}
                                                    value={value}
                                                    sx={{ width: "100%" }}
                                                    error={
                                                        !isNil(
                                                            errors.tariffZoneGroupId,
                                                        )
                                                    }
                                                >
                                                    <MenuItem
                                                        key={NO_ZONE_GROUP}
                                                        value={NO_ZONE_GROUP}
                                                    >
                                                        {NO_ZONE_GROUP}
                                                    </MenuItem>
                                                    {(
                                                        tariffZoneGroupsData?.tariffZoneGroups ??
                                                        []
                                                    ).map((tariffZoneGroup) => {
                                                        return (
                                                            <MenuItem
                                                                key={
                                                                    tariffZoneGroup.id
                                                                }
                                                                value={
                                                                    tariffZoneGroup.id
                                                                }
                                                            >
                                                                {
                                                                    tariffZoneGroup.name
                                                                }
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {!isNil(
                                                    errors.tariffZoneGroupId,
                                                ) && (
                                                    <FormHelperText
                                                        sx={{
                                                            color: "#D32F2F",
                                                        }}
                                                    >
                                                        {
                                                            errors
                                                                .tariffZoneGroupId
                                                                .message
                                                        }
                                                    </FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={importCrownAccessorialLoading}
                    variant="contained"
                >
                    Create Accessorial(s)
                </Button>
            </Grid>
        </Grid>
    );
};

export default AccessorialImporter;
