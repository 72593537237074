import {
    Alert,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sentenceCase } from "change-case";
import dayjs from "dayjs";
import { isNil, values } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import {
    RateType,
    ServiceFragment,
    TariffGroupType,
    TariffType,
    TariffZoneType,
    useContactServicesLazyQuery,
    useFuelProfilesQuery,
    useImportCrownTariffMutation,
    useServicesQuery,
    useShallowContactsQuery,
    useTariffZoneGroupsQuery,
} from "../../generated/graphql";
import { DragAndDropUpload } from "../common/drag-and-drop-upload";
import CompanySelect from "../doc-scanning/company-select";
import { NO_ZONE_GROUP } from "../tariff-importer/use-import-tariff-form";
import {
    ImportTariffFormValuesV2,
    useImportTariffFormV2,
} from "./use-import-tariff-form-v2";

const NO_CONTACT = "NONE (use for default tariffs)";
const NO_FUEL_PROFILE = "NONE";
const unsupportedTariffZoneTypes = [
    TariffZoneType.Zipcode,
    TariffZoneType.Universal,
];
const unsupportedTariffGroupTypes = [TariffGroupType.Transfer];

const TariffImporterV2 = () => {
    const [importCrownTariff, { loading: importCrownTariffLoading }] =
        useImportCrownTariffMutation();

    const { data: contactsData } = useShallowContactsQuery();
    const { data: tariffZoneGroupsData } = useTariffZoneGroupsQuery({
        variables: {
            findTariffZoneGroupsInput: {
                isArchived: false,
            },
        },
    });
    const { form } = useImportTariffFormV2();
    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
    } = form;

    const [successVisible, setSuccessVisible] = useState(false);
    const [errorVisible, setErrorVisible] = useState(false);

    const DEFAULT_MESSAGE = "Error importing tariff";
    const [errorMessage, setErrorMessage] = useState(DEFAULT_MESSAGE);

    useEffect(() => {
        reset({
            contactUuid: NO_CONTACT,
            serviceUuids: [],
            autoSelectAllServices: false,
            name: "",
            tariffZoneType: TariffZoneType.Location,
            unitType: TariffType.PerHundredPounds,
            rateType: RateType.Flat,
            tariffUrl: "",
            tariffGroupType: TariffGroupType.Ordinary,
            useActualWeight: true,
            startDate: dayjs(new Date("2022-01-01")),
            endDate: dayjs(new Date("2030-01-01")),
            fuelProfileUuid: NO_FUEL_PROFILE,
            tariffZoneGroupId: NO_ZONE_GROUP,
        });
    }, [reset]);

    const contactUuid = watch("contactUuid");
    const autoSelectAllServices = watch("autoSelectAllServices");
    const unitType = watch("unitType");

    const { data: fuelProfilesData } = useFuelProfilesQuery();

    // TODO: (Ayushi cc Ashwin) will comment back in once tariff editor reflects contact-specific service levels
    // const [getContactServices, { data: contactServicesData }] =
    //     useContactServicesLazyQuery();
    const [getContactServices] = useContactServicesLazyQuery();
    const { data: globalServicesData } = useServicesQuery({
        fetchPolicy: "cache-first",
    });
    // const contactServices = !isEmpty(contactServicesData?.contact?.services) ? contactServicesData?.contact?.services : globalServicesData?.services;
    const contactServices = globalServicesData?.services;
    const currentServices: ServiceFragment[] =
        (isNil(contactUuid) || contactUuid === NO_CONTACT
            ? globalServicesData?.services
            : contactServices) ?? [];

    useEffect(() => {
        setValue("serviceUuids", []);
        if (!isNil(contactUuid) && contactUuid !== NO_CONTACT) {
            getContactServices({
                variables: {
                    uuid: contactUuid,
                },
            });
        } else {
            setValue("autoSelectAllServices", false);
        }
    }, [contactUuid]);

    useEffect(() => {
        if (autoSelectAllServices) {
            setValue("serviceUuids", []);
        }
    }, [autoSelectAllServices]);

    useEffect(() => {
        if (isNil(unitType) || unitType === TariffType.NoUnits) {
            setValue("rateType", RateType.Flat);
        }
    }, [unitType]);

    const onSubmit = async (data: ImportTariffFormValuesV2) => {
        const {
            companyUuid,
            contactUuid: contactUuidFromForm,
            serviceUuids,
            name,
            tariffZoneType,
            rateType,
            tariffGroupType,
            tariffUrl,
            useActualWeight,
            startDate,
            endDate,
            fuelProfileUuid,
            tariffZoneGroupId,
        } = data;
        try {
            const response = await importCrownTariff({
                variables: {
                    importCrownTariffInput: {
                        companyUuid,
                        contactUuid:
                            isNil(contactUuidFromForm) ||
                            contactUuidFromForm === NO_CONTACT
                                ? null
                                : contactUuidFromForm,
                        serviceUuids: serviceUuids ?? [],
                        name,
                        tariffZoneType,
                        tariffType: unitType,
                        rateType,
                        tariffGroupType,
                        useContactTariffZones: false,
                        tariffZoneGroupId:
                            isNil(tariffZoneGroupId) ||
                            tariffZoneGroupId === NO_ZONE_GROUP
                                ? null
                                : tariffZoneGroupId,
                        tariffUrl,
                        useActualWeight,
                        autoSelectAllServices: data.autoSelectAllServices,
                        startDate,
                        endDate,
                        fuelProfileUuid:
                            isNil(fuelProfileUuid) ||
                            fuelProfileUuid === NO_FUEL_PROFILE
                                ? null
                                : fuelProfileUuid,
                    },
                },
            });
            const error = response.data?.importCrownTariff?.error;
            if (isNil(error)) {
                setErrorMessage(DEFAULT_MESSAGE);
                setSuccessVisible(true);
            } else {
                setErrorMessage(error);
                setErrorVisible(true);
            }
        } catch (e) {
            setErrorVisible(true);
            setErrorMessage(`Error: ${e}`);
        }
    };

    const contactOptions = useMemo(() => {
        let opts = [
            {
                value: NO_CONTACT,
                label: NO_CONTACT,
            },
        ];
        opts = opts.concat(
            contactsData?.contacts?.map((contact) => {
                return {
                    value: contact.uuid,
                    label: contact.displayName,
                };
            }) ?? [],
        );
        return opts;
    }, [contactsData?.contacts]);

    return (
        <Grid container spacing={1} sx={{ padding: 4 }}>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={successVisible}
            >
                <Alert
                    severity="success"
                    onClose={() => setSuccessVisible(false)}
                >
                    Successfully imported tariff
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={errorVisible}
            >
                <Alert severity="error" onClose={() => setErrorVisible(false)}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h4">Crown Tariff Importer V2</Typography>
                <Typography sx={{ size: 6, fontStyle: "italic" }}>
                    For those with brains 🧠
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="tariffUrl"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        return (
                            <DragAndDropUpload
                                filename={
                                    value?.split("/").pop()?.split("?")?.[0]
                                }
                                setUrl={(url: string) => onChange(url)}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="companyUuid"
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <CompanySelect
                            error={error}
                            currentCompanyUuid={value}
                            onChange={(option) => onChange(option?.value)}
                        />
                    )}
                />
                <Controller
                    name="contactUuid"
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => {
                        return (
                            <>
                                <InputLabel id="age">Contact</InputLabel>
                                <ReactSelect
                                    menuPosition="fixed"
                                    onChange={(option) => {
                                        if (!isNil(option)) {
                                            onChange(option.value);
                                        }
                                    }}
                                    options={contactOptions}
                                    placeholder="Select contact"
                                    value={contactOptions.find(
                                        (option) => option.value === value,
                                    )}
                                />
                                {!isNil(error) && (
                                    <FormHelperText sx={{ color: "#D32F2F" }}>
                                        {error.message}
                                    </FormHelperText>
                                )}
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="serviceUuids"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">
                                Services
                                {(isNil(contactUuid) ||
                                    contactUuid === NO_CONTACT) &&
                                    " (Tariff will apply to these services for all contacts)"}
                            </InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value ?? []}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.serviceUuids)}
                                placeholder="Select services"
                                multiple
                                disabled={autoSelectAllServices}
                            >
                                {currentServices.map((service) => {
                                    return (
                                        <MenuItem
                                            key={service.uuid}
                                            value={service.uuid}
                                        >
                                            {sentenceCase(service.name)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {!isNil(errors.serviceUuids) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.serviceUuids.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="autoSelectAllServices"
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControl fullWidth sx={{ paddingLeft: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={field.onChange}
                                    />
                                }
                                label="All services"
                                disabled={
                                    isNil(contactUuid) ||
                                    contactUuid === NO_CONTACT
                                }
                            />
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Tariff Name</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                error={!isNil(errors.name)}
                                value={value}
                                onChange={onChange}
                            />
                            {!isNil(errors.name) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.name.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <InputLabel>Start date</InputLabel>
                            <DatePicker
                                onChange={(newDate) => {
                                    onChange(newDate);
                                }}
                                // renderInput={(props) => <TextField size="small" {...props} />}
                                value={value}
                            />
                        </LocalizationProvider>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <InputLabel>End date</InputLabel>
                            <DatePicker
                                onChange={(newDate) => {
                                    onChange(newDate);
                                }}
                                // renderInput={(props) => <TextField size="small" {...props} />}
                                value={value}
                            />
                        </LocalizationProvider>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="fuelProfileUuid"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Fuel profile</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.fuelProfileUuid)}
                            >
                                <MenuItem
                                    key={NO_FUEL_PROFILE}
                                    value={NO_FUEL_PROFILE}
                                >
                                    {NO_FUEL_PROFILE}
                                </MenuItem>
                                {(fuelProfilesData?.fuelProfiles ?? []).map(
                                    (fuelProfile) => {
                                        return (
                                            <MenuItem
                                                key={fuelProfile.uuid}
                                                value={fuelProfile.uuid}
                                            >
                                                {fuelProfile.name}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                            {!isNil(errors.fuelProfileUuid) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.fuelProfileUuid.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="tariffZoneType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Tariff Type</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.tariffZoneType)}
                            >
                                {values(TariffZoneType).map(
                                    (tariffZoneType) => {
                                        return (
                                            <MenuItem
                                                key={tariffZoneType}
                                                value={tariffZoneType}
                                                disabled={unsupportedTariffZoneTypes.includes(
                                                    tariffZoneType,
                                                )}
                                            >
                                                {sentenceCase(tariffZoneType)}
                                                {unsupportedTariffZoneTypes.includes(
                                                    tariffZoneType,
                                                ) && " (unsupported)"}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                            {!isNil(errors.tariffZoneType) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.tariffZoneType.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="unitType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Unit Type</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.unitType)}
                            >
                                {values(TariffType).map((type) => {
                                    return (
                                        <MenuItem key={type} value={type}>
                                            {sentenceCase(type)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {!isNil(errors.unitType) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.unitType.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="rateType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Rate Type</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.unitType)}
                            >
                                {values(RateType)
                                    .filter(
                                        (rt) =>
                                            (!isNil(unitType) &&
                                                unitType !==
                                                    TariffType.NoUnits) ||
                                            rt === "FLAT",
                                    )
                                    .map((rateType) => {
                                        return (
                                            <MenuItem
                                                key={rateType}
                                                value={rateType}
                                            >
                                                {rateType ===
                                                RateType.QuantityBased
                                                    ? "Multiplier"
                                                    : sentenceCase(rateType)}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {!isNil(errors.unitType) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.unitType.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={2}>
                <Controller
                    name="tariffGroupType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Tariff Group Type</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.tariffGroupType)}
                            >
                                {values(TariffGroupType).map((type) => {
                                    return (
                                        <MenuItem
                                            key={type}
                                            value={type}
                                            disabled={unsupportedTariffGroupTypes.includes(
                                                type,
                                            )}
                                        >
                                            {sentenceCase(type)}
                                            {unsupportedTariffGroupTypes.includes(
                                                type,
                                            ) && " (unsupported)"}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {!isNil(errors.tariffGroupType) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.tariffGroupType.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={3}>
                <Controller
                    control={control}
                    name="useActualWeight"
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControl fullWidth sx={{ paddingLeft: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={field.onChange}
                                    />
                                }
                                label="Use Actual Weight"
                            />
                        </FormControl>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={3}>
                <Controller
                    name="tariffZoneGroupId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel id="age">Zone Group</InputLabel>
                            <Select
                                size="small"
                                onChange={onChange}
                                value={value}
                                sx={{ width: "100%" }}
                                error={!isNil(errors.tariffZoneGroupId)}
                            >
                                <MenuItem
                                    key={NO_ZONE_GROUP}
                                    value={NO_ZONE_GROUP}
                                >
                                    {NO_ZONE_GROUP}
                                </MenuItem>
                                {(
                                    tariffZoneGroupsData?.tariffZoneGroups ?? []
                                ).map((tariffZoneGroup) => {
                                    return (
                                        <MenuItem
                                            key={tariffZoneGroup.id}
                                            value={tariffZoneGroup.id}
                                        >
                                            {tariffZoneGroup.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {!isNil(errors.tariffZoneGroupId) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.tariffZoneGroupId.message}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={importCrownTariffLoading}
                    variant="contained"
                >
                    Create Tariff
                </Button>
            </Grid>
        </Grid>
    );
};

export default TariffImporterV2;
