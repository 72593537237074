import {
    Button,
    Stack,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Chip,
    Typography,
    Box,
} from "@mui/material";
import axios from "axios";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useCompaniesQuery } from "../generated/graphql";

const companyIgnoreList = [
    "898cd577-c6fb-4325-b849-c32d56ccd578", // Stroman - Hodkiewicz-cfa5830f-fb6d-45de-8b93-317b18b6a19c
    "4430f6f7-e894-4d94-918e-12a72189debc", // Wuckert - MacGyver-44b82b02-2b86-44d1-9d31-644c2b943e85
    "c35b4154-89e5-483b-adfb-f2e39a0da97e", // Justis' Playground
    "0f114073-57b4-4b94-95a1-07a95f50d958", // Justis Test Company
];

const HEADERS = {
    "Content-Type": "application/json",
};

const MAIN_URL = "https://app.main.trypallet.com/";
const CANARY_URL = "https://app.canary.trypallet.com/";
const PREVIEW_URL = "https://app.preview.trypallet.com/";
const proxyUrl = () => {
    switch (process.env.REACT_APP_APP_ENV) {
        case "development":
            return "http://localhost:3004";
        case "production":
            return "https://app.trypallet.com";
        case "staging":
            return "https://app.staging.trypallet.com";
        default:
            return "";
    }
    return "";
};

const Canary = () => {
    const { data: companiesData } = useCompaniesQuery();
    const [searchInput, setSearchInput] = useState("");
    const [hoveredCompanyUuid, setHoveredCompanyUuid] = useState<string>();
    const [canaryCompanies, setCanaryCompanies] = useState<
        string[] | undefined
    >(undefined);
    const [previewCompanies, setPreviewCompanies] = useState<
        string[] | undefined
    >(undefined);

    const [stableUsers, setStableUsers] = useState<string[] | undefined>(
        undefined,
    );
    const [canaryUsers, setCanaryUsers] = useState<string[] | undefined>(
        undefined,
    );
    const [previewUsers, setPreviewUsers] = useState<string[] | undefined>(
        undefined,
    );

    const fetchCompanyInstanceMappings = async () => {
        const res = await axios.get(`${proxyUrl()}/proxy/getCompanies`);
        if (res.status === 200) {
            setCanaryCompanies(
                res.data
                    .filter(
                        (companyMapping: { key: string; url: string }) =>
                            companyMapping.url === CANARY_URL,
                    )
                    .map((companyMapping: { key: string; url: string }) =>
                        companyMapping.key.replace("INSTANCE_FOR_", ""),
                    ),
            );
            setPreviewCompanies(
                res.data
                    .filter(
                        (companyMapping: { key: string; url: string }) =>
                            companyMapping.url === PREVIEW_URL,
                    )
                    .map((companyMapping: { key: string; url: string }) =>
                        companyMapping.key.replace("INSTANCE_FOR_", ""),
                    ),
            );
        }
    };

    const fetchUserInstanceMappings = async () => {
        const res = await axios.get(`${proxyUrl()}/proxy/getUsers`);
        if (res.status === 200) {
            setStableUsers(
                res.data
                    .filter(
                        (userMapping: { key: string; url: string }) =>
                            userMapping.url === MAIN_URL,
                    )
                    .map((userMapping: { key: string; url: string }) =>
                        userMapping.key.replace("INSTANCE_FOR_USER_", ""),
                    ),
            );
            setCanaryUsers(
                res.data
                    .filter(
                        (userMapping: { key: string; url: string }) =>
                            userMapping.url === CANARY_URL,
                    )
                    .map((userMapping: { key: string; url: string }) =>
                        userMapping.key.replace("INSTANCE_FOR_USER_", ""),
                    ),
            );
            setPreviewUsers(
                res.data
                    .filter(
                        (userMapping: { key: string; url: string }) =>
                            userMapping.url === PREVIEW_URL,
                    )
                    .map((userMapping: { key: string; url: string }) =>
                        userMapping.key.replace("INSTANCE_FOR_USER_", ""),
                    ),
            );
        }
    };

    const switchCompanyTo = async (
        companyUuid: string,
        companyName: string,
        url: string,
    ) => {
        const options = {
            method: "post",
            headers: HEADERS,
            data: JSON.stringify({
                companyUuid,
                companyName,
                url,
            }),
            url: `${proxyUrl()}/proxy/upsertCompanyAppUrl`,
        };
        await axios(options);
        await fetchCompanyInstanceMappings();
    };

    const switchUserTo = async (userUuid: string, url: string) => {
        const options = {
            method: "post",
            headers: HEADERS,
            data: JSON.stringify({
                userUuid,
                url,
            }),
            url: `${proxyUrl()}/proxy/upsertUserAppUrl`,
        };
        await axios(options);
        await fetchUserInstanceMappings();
    };

    useEffect(() => {
        fetchCompanyInstanceMappings();
        fetchUserInstanceMappings();
    }, []);

    if (
        isNil(canaryCompanies) ||
        isNil(previewCompanies) ||
        isNil(stableUsers) ||
        isNil(canaryUsers) ||
        isNil(previewUsers)
    ) {
        return <Stack sx={{ p: 10, pt: 6 }}>Loading...</Stack>;
    }

    return (
        <Box>
            <Typography variant="h4">Company Environment Switcher</Typography>
            <Stack sx={{ p: 10, pt: 6 }}>
                <Stack direction="row" justifyContent="space-between">
                    <TextField
                        size="small"
                        label="Search Companies"
                        value={searchInput}
                        onChange={(e) => {
                            setSearchInput(e.target.value);
                        }}
                    />
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                    />
                </Stack>
                <TableContainer sx={{ maxHeight: "90vh" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: "30%" }}>
                                    Company
                                </TableCell>
                                <TableCell sx={{ width: "50%" }}>
                                    Instance
                                </TableCell>
                                <TableCell sx={{ width: "20%" }}>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companiesData?.companies
                                .filter(
                                    (company) =>
                                        company.name
                                            .toLowerCase()
                                            .includes(
                                                searchInput.toLowerCase(),
                                            ) &&
                                        !companyIgnoreList.includes(
                                            company.uuid,
                                        ),
                                )
                                .map((company) => {
                                    const onCanary = canaryCompanies.includes(
                                        company.uuid,
                                    );
                                    const onPreview = previewCompanies.includes(
                                        company.uuid,
                                    );

                                    let switchToComponent = (
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => {
                                                switchCompanyTo(
                                                    company.uuid,
                                                    company.name,
                                                    CANARY_URL,
                                                );
                                            }}
                                        >
                                            Switch to Canary
                                        </Button>
                                    );

                                    if (onCanary) {
                                        switchToComponent = (
                                            <Button
                                                size="small"
                                                color="warning"
                                                variant="contained"
                                                onClick={() => {
                                                    switchCompanyTo(
                                                        company.uuid,
                                                        company.name,
                                                        PREVIEW_URL,
                                                    );
                                                }}
                                            >
                                                Switch to Preview
                                            </Button>
                                        );
                                    } else if (onPreview) {
                                        switchToComponent = (
                                            <Button
                                                size="small"
                                                color="info"
                                                variant="contained"
                                                onClick={() => {
                                                    switchCompanyTo(
                                                        company.uuid,
                                                        company.name,
                                                        MAIN_URL,
                                                    );
                                                }}
                                            >
                                                Switch to Main
                                            </Button>
                                        );
                                    }

                                    return (
                                        <TableRow
                                            hover
                                            key={company.uuid}
                                            onMouseEnter={() => {
                                                setHoveredCompanyUuid(
                                                    company.uuid,
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredCompanyUuid(
                                                    undefined,
                                                );
                                            }}
                                        >
                                            <TableCell>
                                                {company.name}
                                            </TableCell>
                                            <TableCell>
                                                {onCanary && (
                                                    <Chip
                                                        label="Canary"
                                                        color="secondary"
                                                    />
                                                )}
                                                {onPreview && (
                                                    <Chip
                                                        label="Preview"
                                                        color="warning"
                                                    />
                                                )}
                                                {!onCanary && !onPreview && (
                                                    <Chip
                                                        label="Main"
                                                        color="info"
                                                    />
                                                )}
                                            </TableCell>
                                            {hoveredCompanyUuid ===
                                            company.uuid ? (
                                                <TableCell>
                                                    {switchToComponent}
                                                </TableCell>
                                            ) : (
                                                <TableCell />
                                            )}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            <Typography variant="h4">User Environment Switcher</Typography>
            <Stack sx={{ p: 10, pt: 6 }}>
                <TableContainer sx={{ maxHeight: "90vh" }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: "30%" }}>
                                    User
                                </TableCell>
                                <TableCell sx={{ width: "50%" }}>
                                    Instance
                                </TableCell>
                                <TableCell sx={{ width: "20%" }}>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[
                                ...stableUsers,
                                ...canaryUsers,
                                ...previewUsers,
                            ].map((user) => {
                                const onCanary = canaryUsers.includes(user);
                                const onPreview = previewUsers.includes(user);

                                let switchToComponent = (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => {
                                            switchUserTo(user, CANARY_URL);
                                        }}
                                    >
                                        Switch to Canary
                                    </Button>
                                );

                                if (onCanary) {
                                    switchToComponent = (
                                        <Button
                                            size="small"
                                            color="warning"
                                            variant="contained"
                                            onClick={() => {
                                                switchUserTo(user, PREVIEW_URL);
                                            }}
                                        >
                                            Switch to Preview
                                        </Button>
                                    );
                                } else if (onPreview) {
                                    switchToComponent = (
                                        <Button
                                            size="small"
                                            color="info"
                                            variant="contained"
                                            onClick={() => {
                                                switchUserTo(user, MAIN_URL);
                                            }}
                                        >
                                            Switch to Main
                                        </Button>
                                    );
                                }

                                return (
                                    <TableRow
                                        hover
                                        key={user}
                                        onMouseEnter={() => {
                                            setHoveredCompanyUuid(user);
                                        }}
                                        onMouseLeave={() => {
                                            setHoveredCompanyUuid(undefined);
                                        }}
                                    >
                                        <TableCell>{user}</TableCell>
                                        <TableCell>
                                            {onCanary && (
                                                <Chip
                                                    label="Canary"
                                                    color="secondary"
                                                />
                                            )}
                                            {onPreview && (
                                                <Chip
                                                    label="Preview"
                                                    color="warning"
                                                />
                                            )}
                                            {!onCanary && !onPreview && (
                                                <Chip
                                                    label="Main"
                                                    color="info"
                                                />
                                            )}
                                        </TableCell>
                                        {hoveredCompanyUuid === user ? (
                                            <TableCell>
                                                {switchToComponent}
                                            </TableCell>
                                        ) : (
                                            <TableCell />
                                        )}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
    );
};

export default Canary;
