/* eslint-disable @typescript-eslint/no-explicit-any */
import ClearAllIcon from "@mui/icons-material/ClearAll";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { isNil } from "lodash";
import React, { useState } from "react";
import { defaultStyles, JsonView } from "react-json-view-lite";
import { shallow } from "zustand/shallow";
import useSchemaStore from "../schema-store";
import { MatchSchemaDocumentJob, MatchStatus } from "../types";

const DocScanningMatchJobTable = ({
    matchSchemaDocumentJobs,
}: {
    matchSchemaDocumentJobs: MatchSchemaDocumentJob[];
}) => {
    const [deleteMatchSchemaDocumentJob, clearAllMatchSchemaDocumentJobs] =
        useSchemaStore(
            (state) => [
                state.deleteMatchSchemaDocumentJob,
                state.clearAllMatchSchemaDocumentJobs,
            ],
            shallow,
        );
    const [jsonResult, setJsonResult] = useState<any | undefined>();

    return (
        <>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>File</TableCell>
                        <TableCell width={250}>Company</TableCell>
                        <TableCell>Expected schema</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Time taken</TableCell>
                        <TableCell>JSON</TableCell>
                        <TableCell>
                            <Tooltip title="Clear All">
                                <IconButton
                                    onClick={() => {
                                        clearAllMatchSchemaDocumentJobs();
                                    }}
                                >
                                    <ClearAllIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {matchSchemaDocumentJobs.slice(0, 5).map((job) => {
                        return (
                            <TableRow key={job.id}>
                                <TableCell>
                                    {job.schemaDocument.key
                                        .split("/")
                                        .slice(-1)}
                                </TableCell>
                                <TableCell>
                                    {job.schemaDocument.company?.name}
                                </TableCell>
                                <TableCell>{job.schema.name}</TableCell>
                                {/* <TableCell>{JSON.stringify(job.resultJson?.documentResults)}</TableCell> */}
                                <TableCell>
                                    {job.matchStatus === MatchStatus.SUCCESS &&
                                        "Success"}
                                    {job.matchStatus === MatchStatus.FAILED &&
                                        job.error}
                                    {job.matchStatus ===
                                        MatchStatus.IN_PROGRESS && "Loading..."}
                                </TableCell>
                                <TableCell>
                                    {!isNil(job.finishedAt)
                                        ? `${dayjs(job.finishedAt).diff(
                                              job.startedAt,
                                              "seconds",
                                          )} sec`
                                        : null}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => {
                                            setJsonResult(job.resultJson);
                                        }}
                                        size="small"
                                    >
                                        View
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            deleteMatchSchemaDocumentJob(
                                                job.id,
                                            );
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Dialog
                sx={{ zIndex: 3000 }}
                onClose={() => {
                    setJsonResult(undefined);
                }}
                open={!isNil(jsonResult)}
            >
                <DialogTitle>JSON Response Data</DialogTitle>
                {!isNil(jsonResult) && (
                    <JsonView
                        data={jsonResult}
                        shouldExpandNode={(level, value, field) =>
                            level >= 0 && field !== "failuresBySchema"
                        }
                        style={defaultStyles}
                    />
                )}
            </Dialog>
        </>
    );
};

export default DocScanningMatchJobTable;
