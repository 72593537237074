import {
    Grid,
    FormHelperText,
    InputLabel,
    TextField,
    Button,
    Snackbar,
    Alert,
} from "@mui/material";
import { isNil } from "lodash";
import { useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    TestOrdersDocument,
    useCreateTestOrderV2Mutation,
} from "../../generated/graphql";
import useCreateTestOrderForm, {
    CreateTestOrderFormValues,
} from "./use-create-test-order-form";

const CreateTestOrderForm = () => {
    const navigate = useNavigate();
    const {
        reset,
        control,
        formState: { errors },
        handleSubmit,
    } = useCreateTestOrderForm();

    const [createSuccessVisible, setCreateSuccessVisible] = useState(false);
    const [createErrorVisible, setCreateErrorVisible] = useState(false);

    const [createTestOrderV2, { loading: createTestOrderV2Loading }] =
        useCreateTestOrderV2Mutation({ refetchQueries: [TestOrdersDocument] });

    const onSubmit: SubmitHandler<CreateTestOrderFormValues> = async (data) => {
        const { orderUuid, totalCharge, totalFreightCharge, totalFuelCharge } =
            data;
        try {
            const response = await createTestOrderV2({
                variables: {
                    createTestOrderRequest: {
                        orderUuid,
                        totalCharge: parseFloat(totalCharge),
                        totalFreightCharge: parseFloat(totalFreightCharge),
                        totalFuelCharge: parseFloat(totalFuelCharge),
                    },
                },
            });
            if (response.data?.createTestOrderV2.success === true) {
                reset();
                setCreateSuccessVisible(true);
                navigate("/test-orders");
            } else {
                setCreateErrorVisible(true);
            }
        } catch (e) {
            setCreateErrorVisible(true);
        }
    };

    return (
        <Grid container spacing={2} style={{ padding: 12 }}>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setCreateSuccessVisible(false)}
                open={createSuccessVisible}
            >
                <Alert>Created</Alert>
            </Snackbar>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setCreateErrorVisible(false)}
                open={createErrorVisible}
            >
                <Alert severity="error">Failed to save</Alert>
            </Snackbar>
            <Grid item xs={4}>
                <Controller
                    name="orderUuid"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Order UUID</InputLabel>
                            <TextField
                                name="orderUuid"
                                size="small"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.orderUuid)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.orderUuid) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.orderUuid?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Expected Total Charge</InputLabel>
                            <TextField
                                name="totalCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalFreightCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>
                                Expected Total Freight Charge
                            </InputLabel>
                            <TextField
                                name="totalFreightCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalFreightCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalFreightCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalFreightCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="totalFuelCharge"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <InputLabel>Expected Total Fuel Charge</InputLabel>
                            <TextField
                                name="totalFuelCharge"
                                size="small"
                                type="number"
                                value={value}
                                required
                                onChange={onChange}
                                error={!isNil(errors.totalFuelCharge)}
                                sx={{ width: "50%" }}
                            />
                            {!isNil(errors.totalFuelCharge) && (
                                <FormHelperText sx={{ color: "#D32F2F" }}>
                                    {errors.totalFuelCharge?.message ?? ""}
                                </FormHelperText>
                            )}
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    disabled={createTestOrderV2Loading}
                    onClick={handleSubmit(onSubmit)}
                >
                    Create
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreateTestOrderForm;
