/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import {
    addressSchemaOverrides,
    consigneeOverrideBasePath,
    generalExtractionSchemaOverrides,
    generalOverrideBasePath,
    shipperOverrideBasePath,
} from "../types";
import AddOverrideDialog from "./add-override-dialog";
import OverridesTable from "./overrides-table";

const PromptOverrides = ({
    schemaOverride,
    setSchemaOverride,
}: {
    schemaOverride: any;
    setSchemaOverride: Dispatch<SetStateAction<any>>;
}) => {
    const [showAddGeneralOverrideDialog, setShowAddGeneralOverrideDialog] =
        useState<boolean>(false);
    const [showAddShipperOverrideDialog, setShowAddShipperOverrideDialog] =
        useState<boolean>(false);
    const [showAddConsigneeOverrideDialog, setShowAddConsigneeOverrideDialog] =
        useState<boolean>(false);

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography>Prompt Overrides</Typography>
                <Button
                    onClick={() => {
                        setShowAddGeneralOverrideDialog(true);
                    }}
                >
                    Add override
                </Button>
            </Stack>
            <OverridesTable
                schemaOverride={schemaOverride}
                setSchemaOverride={setSchemaOverride}
                extractionSchemaOverrides={generalExtractionSchemaOverrides}
                basePath={generalOverrideBasePath}
            />
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography>Shipper Overrides</Typography>
                <Button
                    onClick={() => {
                        setShowAddShipperOverrideDialog(true);
                    }}
                >
                    Add override
                </Button>
            </Stack>
            <OverridesTable
                schemaOverride={schemaOverride}
                setSchemaOverride={setSchemaOverride}
                extractionSchemaOverrides={addressSchemaOverrides}
                basePath={shipperOverrideBasePath}
            />
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography>Consignee Overrides</Typography>
                <Button
                    onClick={() => {
                        setShowAddConsigneeOverrideDialog(true);
                    }}
                >
                    Add override
                </Button>
            </Stack>
            <OverridesTable
                schemaOverride={schemaOverride}
                setSchemaOverride={setSchemaOverride}
                extractionSchemaOverrides={addressSchemaOverrides}
                basePath={consigneeOverrideBasePath}
            />
            <AddOverrideDialog
                open={showAddGeneralOverrideDialog}
                setOpen={setShowAddGeneralOverrideDialog}
                overrides={generalExtractionSchemaOverrides}
                setSchemaOverride={setSchemaOverride}
                title="General overrides"
                basePath={generalOverrideBasePath}
            />
            <AddOverrideDialog
                open={showAddShipperOverrideDialog}
                setOpen={setShowAddShipperOverrideDialog}
                overrides={addressSchemaOverrides}
                setSchemaOverride={setSchemaOverride}
                title="Shipper overrides"
                basePath={shipperOverrideBasePath}
            />
            <AddOverrideDialog
                open={showAddConsigneeOverrideDialog}
                setOpen={setShowAddConsigneeOverrideDialog}
                overrides={addressSchemaOverrides}
                setSchemaOverride={setSchemaOverride}
                title="Consignee overrides"
                basePath={consigneeOverrideBasePath}
            />
        </>
    );
};

export default PromptOverrides;
