/**
 * Return this in the default case of a switch statement to force it to be
 * exhaustive. e.g.:
 *
 * switch (foo) {
 *  default:
 *    return exhaustive(foo);
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function exhaustive(value: never): never {
  throw new Error(`Switch was not exhaustive: ${value}`);
}
