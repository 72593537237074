import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, Typography } from "@mui/material";
import { isNil } from "lodash";
import { useDropzone } from "react-dropzone";
import { useGeneratePreSignedPutUrlMutation } from "../../generated/graphql";

export const DragAndDropUpload = ({
    filename,
    setUrl,
}: {
    filename: string | undefined;
    setUrl: (url: string) => void;
}) => {
    const [generatePreSignedPutUrl] = useGeneratePreSignedPutUrlMutation();
    const onDrop = async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (isNil(file)) {
            return;
        }

        const data = await generatePreSignedPutUrl({
            variables: {
                filename: file.name,
                filetype: file.type,
            },
        });
        const preSignedPutUrl = data.data?.generatePreSignedPutUrl;
        if (!isNil(preSignedPutUrl)) {
            await fetch(preSignedPutUrl, {
                method: "PUT",
                body: file,
                headers: { "Content-Type": file.type },
            });
            setUrl(preSignedPutUrl);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <Box
            {...getRootProps()}
            border={2}
            borderRadius={4}
            borderColor={isDragActive ? "primary.main" : "text.secondary"}
            p={4}
            textAlign="center"
            bgcolor={isDragActive ? "primary.light" : "background.paper"}
        >
            <input {...getInputProps()} />
            {isNil(filename) && (
                <Typography variant="h6" gutterBottom>
                    {isDragActive
                        ? "Drop the file here ..."
                        : "Drag & drop a file here, or click to select a file"}
                </Typography>
            )}
            {!isNil(filename) && (
                <Typography variant="h6" gutterBottom>
                    {filename}
                </Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
            >
                Upload
            </Button>
        </Box>
    );
};
