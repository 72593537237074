import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import EditTestOrderForm from "../../../components/test-orders/edit-test-order-form";
import { RequireAuth } from "../../../hooks/use-auth";

const TestOrderEditPage = () => {
    const { uuid } = useParams();
    return (
        <RequireAuth>
            {!isNil(uuid) && typeof uuid === "string" && (
                <EditTestOrderForm testOrderUuid={uuid} />
            )}
        </RequireAuth>
    );
};

export default TestOrderEditPage;
