import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import EdiMessagesTable from "../../../components/edi/edi-messages-table";
import {
    useCompaniesQuery,
    useEdiTransactionsByCompanyLazyQuery,
} from "../../../generated/graphql";

const EDI_MESSAGE_PAGE_SIZE = 15;

const ViewEdiMessagesPage = () => {
    const [canGoPrevious, setCanGoPrevious] = useState(false);
    const [canGoNext, setCanGoNext] = useState(false);
    const [companyUuid, setCompanyUuid] = useState<string | null>(null);

    const { data: companiesData } = useCompaniesQuery();
    const [
        findEdiTransactions,
        { data: ediTransactionsData, loading: ediTransactionsLoading },
    ] = useEdiTransactionsByCompanyLazyQuery();

    const selectOptions = useMemo(() => {
        return companiesData?.companies?.map((company) => {
            return {
                value: company.uuid,
                label: company.name,
            };
        });
    }, [companiesData]);

    /**
     * Graphql pagination args
     * https://relay.dev/graphql/connections.htm
     */
    interface FetchEdiTransactionsParams {
        first?: number | null | undefined;
        after?: string | null | undefined;
        last?: number | null | undefined;
        before?: string | null | undefined;
    }

    const fetchEdiTransactions = async ({
        first,
        after,
        last,
        before,
    }: FetchEdiTransactionsParams) => {
        if (isNil(companyUuid) || isEmpty(companyUuid)) {
            return;
        }
        await findEdiTransactions({
            variables: {
                companyUuid,
                first,
                after,
                last,
                before,
            },
        });
    };

    useEffect(() => {
        fetchEdiTransactions({ first: EDI_MESSAGE_PAGE_SIZE });
    }, [companyUuid]);

    const paginationButtons = (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Button
                    onClick={async () => {
                        await fetchEdiTransactions({
                            last: EDI_MESSAGE_PAGE_SIZE,
                            before: ediTransactionsData
                                ?.ediTransactionsByCompany.pageInfo.startCursor,
                        });
                        setCanGoPrevious(false);
                        setCanGoNext(true);
                    }}
                    disabled={
                        ediTransactionsLoading ||
                        (ediTransactionsData?.ediTransactionsByCompany.pageInfo
                            .hasPreviousPage !== true &&
                            !canGoPrevious)
                    }
                    variant="contained"
                    sx={{ mr: 2 }}
                    startIcon={<ArrowLeft />}
                >
                    Previous page
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    onClick={async () => {
                        await fetchEdiTransactions({
                            first: EDI_MESSAGE_PAGE_SIZE,
                            after: ediTransactionsData?.ediTransactionsByCompany
                                .pageInfo.endCursor,
                        });
                        setCanGoPrevious(true);
                        setCanGoNext(false);
                    }}
                    disabled={
                        ediTransactionsLoading ||
                        (ediTransactionsData?.ediTransactionsByCompany.pageInfo
                            .hasNextPage !== true &&
                            !canGoNext)
                    }
                    variant="contained"
                    endIcon={<ArrowRight />}
                >
                    Next page
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={2} sx={{ padding: 10 }}>
            <Grid item xs={12}>
                <Typography variant="button">
                    <strong>Select company</strong>
                </Typography>
                <Select
                    options={selectOptions}
                    onChange={(option) => {
                        if (!isNil(option) && !isNil(option.value)) {
                            setCompanyUuid(option.value);
                        }
                    }}
                    placeholder="Select company"
                />
            </Grid>
            <Grid item xs={12}>
                {paginationButtons}
            </Grid>
            {ediTransactionsLoading === true ? (
                <CircularProgress />
            ) : (
                <Grid item xs={12}>
                    <EdiMessagesTable
                        ediTransactionsData={ediTransactionsData}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ViewEdiMessagesPage;
