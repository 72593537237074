import InvoiceEmails from "../../components/invoice-emails/invoice-emails";
import { RequireAuth } from "../../hooks/use-auth";

const InvoiceEmailsPage = () => {
    return (
        <RequireAuth>
            <InvoiceEmails />
        </RequireAuth>
    );
};

export default InvoiceEmailsPage;
